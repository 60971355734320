import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Input, Label } from "reactstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { ImageFormComponent } from ".././Images/ImageFormComponent";
import { CarouselComponent } from ".././Images/CarouselComponent";
import { ImageFondo } from ".././Images/ImageFondo";
import ModalFormularioXvPre from ".././ModalFormularioXvPre";

export const FormularioXvAuto = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idEvento = searchParams.get("id");
  const [dataEvento, setDataEvento] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isAlreadySet, setAlreadySet] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Obtener los datos actuales del localStorage o crear un nuevo objeto si no existen
    const formData = JSON.parse(localStorage.getItem("formData")) || {};

    // Actualizar el campo correspondiente en el objeto
    formData[name] = value;

    // Guardar el objeto actualizado en el localStorage
    localStorage.setItem("formData", JSON.stringify(formData));
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/Eventos/${idEvento}`);
      let data = response.data;
      setDataEvento({ ...data });
      if (data.enablePortada == true || data.enablePortadaBoda == true) {
        setAlreadySet(true);
      }
      //To be added later
      //console.log(dataEvento);
      //const images = await axios.get(`/api/Images/FromEvent/${idEvento}`);
      //console.log(images.data);
      //setDataEvento({...dataEvento, ...images.data});
      console.log(response.data);
      console.log(dataEvento);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Something went wrong, please try again");
    }
  };

  const capitalizeWords = (str) => {
    const exceptions = new Set(["of", "to", "and", "it", "this", "that"]);
    return str
      .toLocaleLowerCase("es")
      .replace(/(?:^|\s)\p{L}/gu, (match) => match.toLocaleUpperCase("es"))
      .split(" ")
      .map((word) =>
        exceptions.has(word.toLowerCase()) ? word.toLowerCase() : word
      )
      .join(" ");
  };
   // Obtener formData de localStorage si existe
   const savedFormData = JSON.parse(localStorage.getItem("formData")) || {};


  const initialValues = {
    cancion:savedFormData.cancion || "",
    color1:savedFormData.color1 || "#ffeeff",
    color2:savedFormData.color2 || "#ffffee",
    D1esposo:savedFormData.D1esposo || "",
    D1NombreQuince:savedFormData.D1NombreQuince || "",
    D1fechaDeBoda:savedFormData.D1fechaDeBoda || "",
    D1numeroQuinceañera:savedFormData.D1numeroQuinceañera || "",
    D1numeroNovio:savedFormData.D1numeroNovio || "",
    D1numeroWiddingAdmin:savedFormData.D1numeroWiddingAdmin || "",
    D1compradorInv:savedFormData.D1compradorInv || "",
    D2descipcion:savedFormData.D2descipcion ||
      "I've been dreaming of this day, and now I am excited to celebrate it with you!",
    D3QuinceañeraMadre:savedFormData.D3QuinceañeraMadre || "",
    D3QuinceañeraPadre:savedFormData.D3QuinceañeraPadre || "",
    D3QuinceañeraMadrina:savedFormData.D3QuinceañeraMadrina || "",
    D3QuinceañeraPadrino:savedFormData.D3QuinceañeraPadrino || "",
    D4ceremoniaFecha:savedFormData.D4ceremoniaFecha || "",
    D4ceremoniaNombreLugar:savedFormData.D4ceremoniaNombreLugar || "",
    D4ceremoniaUrl:savedFormData.D4ceremoniaUrl || "",
    D4recepcionFecha:savedFormData.D4recepcionFecha || "",
    D4recepcionNombreLugar:savedFormData.D4recepcionNombreLugar || "",
    D4recepcionUrl:savedFormData.D4recepcionUrl || "",
    // D5ceremonia: "",
    // D5ceremoniaCivil: "",
    // D5cena: "",
    // D5recepcion: "",
    // D5coctel: "",
    // D5baile: "",
    D7hotel1lugar:savedFormData.D7hotel1lugar || "",
    D7hotel1url:savedFormData.D7hotel1url || "",
    D7hotel2lugar:savedFormData.D7hotel2lugar || "",
    D7hotel2url:savedFormData.D7hotel2url || "",
    D8linkRegalo1:savedFormData.D8linkRegalo1 || "",
    D8linkRegalo2:savedFormData.D8linkRegalo2 || "",
    D8linkRegalo3:savedFormData.D8linkRegalo3 || "",
    D8linkRegalo4:savedFormData.D8linkRegalo4 || "",
    Detalles:savedFormData.Detalles || "",
    Vestimenta:savedFormData.Vestimenta || "",
    VestimentaAviso:savedFormData.VestimentaAviso || "",
    PceremoniaReligiosa:savedFormData.PceremoniaReligiosa || "Join us as we receive the Lord's Blessing",
    PceremoniaReligiosaTitulo:savedFormData.PceremoniaReligiosaTitulo || "CEREMONY",
    Precepcion:savedFormData.Precepcion || "Photo Session",
    PrecepcionTitulo:savedFormData.PrecepcionTitulo || "RECEPTION",
    PbaileXv:savedFormData.PbaileXv || "The First Dance",
    PbaileXvTitulo:savedFormData.PbaileXvTitulo || "WALTZ",
    Pcena:savedFormData.Pcena || "A Special Meal",
    PcenaTitulo:savedFormData.PcenaTitulo || "DINNER",
    Pcoctel:savedFormData.Pcoctel || "Try a Refreshing Coctail",
    PcoctelTitulo:savedFormData.PcoctelTitulo || "Coctail",
    Pbaile:savedFormData.Pbaile || "Dance With the DJ",
    PbaileTitulo:savedFormData.PbaileTitulo || "DANCE TIME",
    imgFondo:savedFormData.imgFondo || "",
    tipoLetra:savedFormData.tipoLetra || "",
  };

  const onSubmit = async (values) => {
    const confirmed = window.confirm(
      "You can only submit this form once. Do you wish to continue?"
    );

    if (!confirmed) {
      console.log("Form submission cancelled");
      return;
    }
    toast.loading(`Sumbitting form...`);
    setLoadingSend(true);
    console.log("Datos del formulario:", values, idEvento);

    let tituloContador = "My 15th Birthday!";
    let tituloPortada = "Save the date";
    let tituloConfirmacion = "for the party";
    switch (dataEvento?.tipoEvento) {
      case "XV":
        tituloContador = "My 15th Birthday!";
        tituloPortada = "Save the date";
        tituloConfirmacion = "for the party";
        break;
      case "Evento":
        tituloContador = "My Party!";
        tituloPortada = "Save the date";
        tituloConfirmacion = "for the party";
        break;
      case "Cumpleaños":
        tituloContador = "My Birthday Party!";
        tituloPortada = "Save the date";
        tituloConfirmacion = "for the party";
        break;
      case "Bautizo":
        tituloContador = "¡My Baptism!";
        tituloPortada = "Save the date";
        tituloConfirmacion = "for the party";
        break;
      case "ComunionConfirmacion":
        tituloContador = "¡My First Communion!";
        tituloPortada = "Save the date";
        tituloConfirmacion = "for the party";
        break;
      default:
        break;
    }

    console.log(tituloContador);

    const portada = {
      // portadaId: 2,
      textColor: values.textColor,
      cancion: values.cancion,
      imgPortada: "",
      nombre: capitalizeWords(values.D1NombreQuince),
      fechaDeBoda: values.D1fechaDeBoda,
      titulo: tituloPortada,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      posicion: ""
    };

    const contador = {
      titulo: tituloContador,
      descripcion: values.D2descipcion,
      fechaNewDate:
        values.D1fechaDeBoda + "December 12, 2024 00:00:00 GMT+00:00",
      fotoIzquierda: "",
      fotoDerecha: "",
      marcoEnable: true,
      textColor: null,
      imgFondo: values.imgFondo,
      contador: true,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    let cantidad;

    if (values.D3QuinceañeraMadre || values.D3QuinceañeraPadre) {
      cantidad = 1;

      if (values.D3QuinceañeraMadrina || values.D3QuinceañeraPadrino) {
        cantidad = 2;
      }
    } else {
      cantidad = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
    }

    const padres = {
      cantidad: cantidad,
      titulo: "With the loving support of:",
      descripcion: null,
      titulo1: "My Parents",
      madre: capitalizeWords(values.D3QuinceañeraMadre),
      padre: capitalizeWords(values.D3QuinceañeraPadre),
      titulo2: "My Godparents",
      madrina: capitalizeWords(values.D3QuinceañeraMadrina),
      padrino: capitalizeWords(values.D3QuinceañeraPadrino),
      titulo3: "Additional thanks",
      madrina2: "....",
      padrino2: "....",
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const ceremonia = {
      dobleEnable: false,
      ceremoniaEnable: values.D4ceremoniaFecha ? true : false,
      ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
      ceremoniaHora: values.D4ceremoniaFecha,
      ceremoniaNombreLugar: capitalizeWords(values.D4ceremoniaNombreLugar),
      ceremoniaLugarMap: "",
      ceremoniaUrl: values.D4ceremoniaUrl,
      ceremoniaImg: "",
      ceremoniaTitulo: "",
      recepcionImg: "",
      recepcionTitulo: "",
      recepcionEnable: values.D4recepcionFecha ? true : false,
      recepcionFecha: "Sábado, 25 de Mayo de 2024.",
      recepcionHora: values.D4recepcionFecha,
      recepcionNombreLugar: capitalizeWords(values.D4recepcionNombreLugar),
      recepcionLugarMap: "",
      recepcionUrl: values.D4recepcionUrl,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const vestimenta = {
      vestimenta: values.Vestimenta,
      messageVestimenta: values.VestimentaAviso,
      urlVestimentaMujer: null,
      urlVestimentaHombre: null,
      imgFondo:
        "https://plus.unsplash.com/premium_photo-1683140516020-28d86d877ee1?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGJlaWdlfGVufDB8fDB8fHww",
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      textHombre: "",
      textMujer: "",
      colores: "",
    };

    const confirmacion = {
      tituloConfirmacion:
        "Thank you for your time \n I Hope to see you there!",
      aviso: values.Detalles,
      numeroConfirmacion: "+1" + values.D1numeroWiddingAdmin,
      textoConfirmacion: tituloConfirmacion,
      textWhatsApp: null,
      textColor: null,
      imgFondo: values.imgFondo,
      enableNum: false,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      confetti: false,
    };

    const programa = {
      ceremoniaReligiosa: values.PceremoniaReligiosa,
      ceremoniaReligiosaImg: "",
      ceremoniaReligiosaTitulo: values.PceremoniaReligiosaTitulo,
      recepcion: values.Precepcion,
      recepcionImg: "",
      recepcionTitulo: values.PrecepcionTitulo,
      baileXv: values.PbaileXv,
      baileXvImg: "",
      baileXvTitulo: values.PbaileXvTitulo,
      cena: values.Pcena,
      cenaImg: "",
      cenaTitulo: values.PcenaTitulo,
      coctel: values.Pcoctel,
      coctelImg: "",
      coctelTitulo: values.PcoctelTitulo,
      baile: values.Pbaile,
      baileImg: "",
      baileTitulo: values.PbaileTitulo,
      idEvento: idEvento,
      textColor: "",
      titulo: "",
      descripcion: "",
      imgFondo: values.imgFondo,
      tipoLetra: values.tipoLetra,
    };

    const regalos = {
      cantidadRegalos: values.D8linkRegalo1 ? 3 : 1,
      titulo: "Registry",
      descripcion:
        "Your attendance is the best gift I could hope for, but if you'd like to give me a present, here are a few options for this special day!",
      link1: values.D8linkRegalo1,
      imgLink1:
        "https://i.postimg.cc/1XkCJfJ8/amazon.png",
      link1Enable: true,
      link2: values.D8linkRegalo2,
      imgLink2:
        "https://i.postimg.cc/J0DYNbcw/target.png",
      link2Enable: true,
      link3: values.D8linkRegalo3,
      imgLink3: "",
      link3Enable: true,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const hospedaje = {
      disponibleDos: true,
      descripcion:
        "For a more pleasant stay, I reccomend the following hotels.",
      hotel1Titulo: "...",
      hotel1lugar: values.D7hotel1lugar,
      hotel1url: values.D7hotel1url,
      hotel2Titulo: "...",
      hotel2lugar: values.D7hotel2lugar,
      hotel2url: values.D7hotel2url,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const colores = {
      color1: values.color1,
      color2: values.color2,
      idEvento: idEvento,
    };

    const invitados = {
      imgFondo: values.imgFondo,
      titulo: "",
      entradasText: "to the party.",
      colorFondoName: "",
      eventoTitulo: "",
      limiteAccesos: 150,
      IdEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    let requiredImageKeys = [
      "eventId",
      "coverBackgroundImg",
      "coverBackgroundFile",
      "counterLeftImg",
      "counterLeftFile",
      "counterRightImg",
      "counterRightFile",
      "carousel",
    ];

    let data = new FormData();
    requiredImageKeys.forEach((value) => {
      switch (value) {
        case "carousel":
          let items = values[value];
          if (typeof items == "undefined") {
            break;
          }

          items.forEach((x, index) => {
            data.append(`${value}[${index}].Id`, x.id);
            data.append(`${value}[${index}].Url`, x.url);
            data.append(`${value}[${index}].Replacement`, x.replacement);
            data.append(`${value}[${index}].Deleted`, x.deleted);
          });
          break;
        case "eventId":
          data.append(value, idEvento);
          break;
        default:
          data.append(value, values[value]);
          break;
      }
    });

    // Petición para el objeto hospedaje
    dataEvento.fechaFin = "Formulario";

    const eventData = { ...dataEvento, enablePortada: true };

    try {
      // Petición para el objeto portada
      await axios.post("/api/Seccionportadum", portada);

      // Petición para el objeto hospedaje
      await axios.post("/api/Colores", colores);

      // Petición para el objeto contador
      await axios.post("/api/Seccioncontador", contador);

      // Petición para el objeto padres
      await axios.post("/api/Seccionpadre", padres);

      // Petición para el objeto ceremonia
      await axios.post("/api/Seccionceremonium", ceremonia);

      // Petición para el objeto vestimenta
      await axios.post("/api/Seccionvestimentum", vestimenta);

      // Petición para el objeto confirmacion
      await axios.post("/api/Seccionconfirmacion", confirmacion);

      // Petición para el objeto programa
      await axios.post("/api/Seccionprograma", programa);

      // Petición para el objeto regalos
      await axios.post("/api/Seccionregalo", regalos);

      // Petición para el objeto hospedaje
      await axios.post("/api/Seccionhospedaje", hospedaje);

      // Petición para el objeto hospedaje
      await axios.post("/api/Seccioninvitado", invitados);
      dataEvento.fechaFin = "Formulario";

      const updateImages = await axios.post(`/api/Images`, data);
      console.log("Respuesta del servidor:", updateImages.data);

      const updateResponse = await axios.put(
        `/api/Eventos/${idEvento}`,
        eventData
      );
      console.log(updateResponse.data);

      console.log("Information sent");
      alert("Your form has been submitted!");
      toast.dismiss();
      window.location.href = "/en";
      setLoadingSend(false);
    } catch (error) {
      alert("This form has already been submitted");
      toast.dismiss();
      console.error("Error sending information:", error);
      setLoadingSend(false);
    }
  };

  const clearFormData = () => {
    const confirmed = window.confirm("Do you want to restart the form and delete the saved data?");


    if (!confirmed) {
      console.log("Reseteo de formulario cancelado");
      return
    }
    localStorage.removeItem("formData");
    // Opcionalmente, podrías recargar la página o resetear los valores
    window.location.reload(); // Para recargar la página y resetear el formulario
  };

  const [useImage, setUseImage] = useState(false);

  const handleCheckboxChange = (e) => {
    setUseImage(e.target.checked);
  };

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className="w-full bg-black sm:p-2"
      style={{
        backgroundImage: `url(https://digitalrsvp.mx/api/Images/1cb8085a-ac04-46ca-b7aa-b1a20dd68a30)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      {loading ? (
        <h2>Loading...</h2>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={(e) => {
            onSubmit(e);
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleReset,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form
              className="modal-form-content  flex flex-col items-center md:p-24 sm:p-4 "
              onSubmit={handleSubmit}
            >
              <br />
              <h2 className="title-form text-center">INVITATION FORM</h2>
              <br />
              {isAlreadySet && (
                <h3 className="text-red-800 text-center">
                  NOTICE! This form has already been submitted
                </h3>
              )}
              <h3 className="text-red-800 text-center">
                
              </h3>
              <br />
              <h3 className="text-red-800 text-center">
                Please check all information carefully. Thank you in advance!
              </h3>
              <br />
              <br />
              <div className="w-full pb-4 flex sm:justify-center sm:items-center md:justify-end md:items-end">
                <button
                  type="button"
                  onClick={clearFormData}
                  className="mt-4 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                >
                  Delete saved data
                </button>
                </div>
              <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                <div className="col-span-1 m-3">
                  <Label>Your Name</Label>
                  <Input
                    type="text"
                    maxLength="50"
                    name="D1compradorInv"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D1compradorInv}
                    className={errors.D1compradorInv ? "input-error" : ""}
                  />
                  {touched.D1compradorInv && errors.D1compradorInv && (
                    <div className="color-error">{errors.D1compradorInv}</div>
                  )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>Phone number to receive attendance confirmations</Label>
                  <Input
                    type="text"
                    maxLength="50"
                    name="D1numeroWiddingAdmin"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D1numeroWiddingAdmin}
                    className={errors.D1numeroWiddingAdmin ? "input-error" : ""}
                  />
                  {touched.D1numeroWiddingAdmin &&
                    errors.D1numeroWiddingAdmin && (
                      <div className="color-error">
                        {errors.D1numeroWiddingAdmin}
                      </div>
                    )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>
                    {dataEvento?.tipoEvento == "XV"
                      ? "Quinceañer@"
                      : "Who is being celebrated?"}
                  </Label>
                  <Input
                    type="text"
                    maxLength="50"
                    name="D1NombreQuince"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D1NombreQuince}
                    className={errors.D1NombreQuince ? "input-error" : ""}
                  />
                  {touched.D1NombreQuince && errors.D1NombreQuince && (
                    <div className="color-error">{errors.D1NombreQuince}</div>
                  )}
                </div>
                <ImageFormComponent
                  label="Cover photo, prefferably in vertical position"
                  ingles={true}
                  field="coverBackground"
                  element={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  edit={false}
                />
                <div className="col-span-1 m-3">
                  <Label>Date and time of the event</Label>
                  <Input
                    type="datetime-local"
                    name="D1fechaDeBoda"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D1fechaDeBoda}
                    className={errors.D1fechaDeBoda ? "input-error" : ""}
                  />
                  {touched.D1fechaDeBoda && errors.D1fechaDeBoda && (
                    <div className="color-error">{errors.D1fechaDeBoda}</div>
                  )}
                </div>
                {/* ... Puedes seguir agregando más campos aquí ... */}
                <div className="col-span-1 m-3">
                  <Label>Greeting message</Label>
                  <br />
                  <textarea
                    type="text"
                    name="D2descipcion"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D2descipcion}
                    className={`w-[90%] h-28 ${
                      errors.D2descipcion ? "input-error" : ""
                    }`}
                  />
                  {touched.D2descipcion && errors.D2descipcion && (
                    <div className="color-error">{errors.D2descipcion}</div>
                  )}
                </div>
                <ImageFormComponent
                  label="Photo on top of the time"
                  ingles={true}
                  field="counterLeft"
                  element={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  edit={false}
                />
                <ImageFormComponent
                  label="Photo beneath the timer"
                  ingles={true}
                  field="counterRight"
                  element={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  edit={false}
                />
                <h2 className="text-center mt-4 md:col-span-2 title-form">
                  BACKGROUND 
                </h2>
                <div className="col-span-1 m-3">
                  <Label>
                    <input
                      type="checkbox"
                      checked={useImage}
                      onChange={handleCheckboxChange}
                    />
                    Use a theme
                  </Label>
                </div>

                {!useImage && (
                  <div>
                    <div className="col-span-1 m-3">
                      <Label>1st Color</Label>
                      <Input
                        type="color"
                        name="color1"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.color1}
                        className={errors.color1 ? "input-error" : ""}
                      />
                      {touched.color1 && errors.color1 && (
                        <div className="color-error">{errors.color1}</div>
                      )}
                    </div>
                    <div className="col-span-1 m-3">
                      <Label>2nd Color</Label>
                      <Input
                        type="color"
                        name="color2"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.color2}
                        className={errors.color2 ? "input-error" : ""}
                      />
                      {touched.color2 && errors.color2 && (
                        <div className="color-error">{errors.color2}</div>
                      )}
                    </div>
                  </div>
                )}

                {useImage && (
                  <div className="md:col-span-2 sm:col-span-1">
                    <ImageFondo
                      label="Choose an option"
                      ingles={true}
                      name="imgFondo"
                      onChange={handleChange}
                      value={values.imgFondo}
                      className={errors.imgFondo ? "input-error" : ""}
                    />
                  </div>
                )}
                {/* <div className="col-span-1 m-3">
                  <Label>Numero Novio</Label>
                  <Input
                    type="text"
                    name="D1numeroNovio"
                    onChange={handleChange}
                    value={values.D1numeroNovio}
                    className={errors.D1numeroNovio ? "input-error" : ""}
                  />
                  {touched.D1numeroNovio && errors.D1numeroNovio && (
                    <div className="color-error">{errors.D1numeroNovio}</div>
                  )}
                </div> */}
              </div>

              <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                <div className="col-span-1 m-3">
                  <Label>
                    {" "}
                    {dataEvento?.tipoEvento == "XV"
                      ? "Father's name"
                      : "Father's name"}
                  </Label>
                  <Input
                    type="text"
                    name="D3QuinceañeraMadre"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D3QuinceañeraMadre}
                    className={errors.D3QuinceañeraMadre ? "input-error" : ""}
                  />
                  {touched.D3QuinceañeraMadre && errors.D3QuinceañeraMadre && (
                    <div className="color-error">
                      {errors.D3QuinceañeraMadre}
                    </div>
                  )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>
                    {" "}
                    {dataEvento?.tipoEvento == "XV"
                      ? "Mother's name"
                      : "Mother's name"}
                  </Label>
                  <Input
                    type="text"
                    name="D3QuinceañeraPadre"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D3QuinceañeraPadre}
                    className={errors.D3QuinceañeraPadre ? "input-error" : ""}
                  />
                  {touched.D3QuinceañeraPadre && errors.D3QuinceañeraPadre && (
                    <div className="color-error">
                      {errors.D3QuinceañeraPadre}
                    </div>
                  )}
                </div>
                {/* <div className="col-span-1 m-3">
                  <Label>Nombre de la Madrina</Label>
                  <Input
                    type="text"
                    name="D3QuinceañeraMadrina"
                    onChange={handleChange}
                    value={values.D3QuinceañeraMadrina}
                    className={errors.D3QuinceañeraMadrina ? "input-error" : ""}
                  />
                  {touched.D3QuinceañeraMadrina &&
                    errors.D3QuinceañeraMadrina && (
                      <div className="color-error">
                        {errors.D3QuinceañeraMadrina}
                      </div>
                    )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>Nombre del Padrino</Label>
                  <Input
                    type="text"
                    name="D3QuinceañeraPadrino"
                    onChange={handleChange}
                    value={values.D3QuinceañeraPadrino}
                    className={errors.D3QuinceañeraPadrino ? "input-error" : ""}
                  />
                  {touched.D3QuinceañeraPadrino &&
                    errors.D3QuinceañeraPadrino && (
                      <div className="color-error">
                        {errors.D3QuinceañeraPadrino}
                      </div>
                    )}
                </div> */}
              </div>
              <br />
              {dataEvento?.tipoEvento == "Birthday Party" ||
              dataEvento?.tipoEvento == "Event" ? (
                ""
              ) : (
                <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                  <h2 className="text-center md:col-span-2 title-form">
                    CEREMONY{" "}
                  </h2>
                  <div className="col-span-1 m-3">
                    <Label>Date and time</Label>
                    <Input
                      type="datetime-local"
                      name="D4ceremoniaFecha"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4ceremoniaFecha}
                      className={errors.D4ceremoniaFecha ? "input-error" : ""}
                    />
                    {touched.D4ceremoniaFecha && errors.D4ceremoniaFecha && (
                      <div className="color-error">
                        {errors.D4ceremoniaFecha}
                      </div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <Label>Address</Label>
                    <Input
                      type="text"
                      name="D4ceremoniaNombreLugar"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4ceremoniaNombreLugar}
                      className={
                        errors.D4ceremoniaNombreLugar ? "input-error" : ""
                      }
                    />
                    {touched.D4ceremoniaNombreLugar &&
                      errors.D4ceremoniaNombreLugar && (
                        <div className="color-error">
                          {errors.D4ceremoniaNombreLugar}
                        </div>
                      )}
                  </div>
                  <div className="col-span-1 m-3">
                    <Label>Location Link</Label>
                    <Input
                      type="text"
                      name="D4ceremoniaUrl"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D4ceremoniaUrl}
                      className={errors.D4ceremoniaUrl ? "input-error" : ""}
                    />
                    {touched.D4ceremoniaUrl && errors.D4ceremoniaUrl && (
                      <div className="color-error">{errors.D4ceremoniaUrl}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <Label>How to get Link in Google Maps</Label>
                    <img
                      src="https://i.postimg.cc/CLz26d5q/Dise-o-sin-t-tulo.gif"
                      className="w-full h-[30vh]"
                    />
                  </div>
                </div>
              )}
              <br />
              <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                <br />
                <h2 className="text-center md:col-span-2 title-form">
                  RECEPTION{" "}
                </h2>
                <div className="col-span-1 m-3">
                  <Label>Date and Time</Label>
                  <Input
                    type="datetime-local"
                    name="D4recepcionFecha"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D4recepcionFecha}
                    className={errors.D4recepcionFecha ? "input-error" : ""}
                  />
                  {touched.D4recepcionFecha && errors.D4recepcionFecha && (
                    <div className="color-error">{errors.D4recepcionFecha}</div>
                  )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>Address</Label>
                  <Input
                    type="text"
                    name="D4recepcionNombreLugar"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D4recepcionNombreLugar}
                    className={
                      errors.D4recepcionNombreLugar ? "input-error" : ""
                    }
                  />
                  {touched.D4recepcionNombreLugar &&
                    errors.D4recepcionNombreLugar && (
                      <div className="color-error">
                        {errors.D4recepcionNombreLugar}
                      </div>
                    )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>Location Link</Label>
                  <Input
                    type="text"
                    name="D4recepcionUrl"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.D4recepcionUrl}
                    className={errors.D4recepcionUrl ? "input-error" : ""}
                  />
                  {touched.D4recepcionUrl && errors.D4recepcionUrl && (
                    <div className="color-error">{errors.D4recepcionUrl}</div>
                  )}
                </div>
                <div className="col-span-1 m-3">
                  <Label>How to get Link</Label>
                  <img
                    src="https://i.postimg.cc/CLz26d5q/Dise-o-sin-t-tulo.gif"
                    className="w-full h-[30vh]"
                  />
                </div>
              </div>
              <br />
              {dataEvento?.enableHospedaje ? (
                <>
                  <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                    <br />
                    <h2 className="text-center md:col-span-2 title-form">
                      Hotel # 1
                    </h2>
                    <div className="col-span-1 m-3">
                      <Label>Hotel #1 Address</Label>
                      <Input
                        type="text"
                        name="D7hotel1lugar"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel1lugar}
                        className={errors.D7hotel1lugar ? "input-error" : ""}
                      />
                      {touched.D7hotel1lugar && errors.D7hotel1lugar && (
                        <div className="color-error">
                          {errors.D7hotel1lugar}
                        </div>
                      )}
                    </div>
                    <div className="col-span-1 m-3">
                      <Label>Hotel #1 Location Link</Label>
                      <Input
                        type="text"
                        name="D7hotel1url"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel1url}
                        className={errors.D7hotel1url ? "input-error" : ""}
                      />
                      {touched.D7hotel1url && errors.D7hotel1url && (
                        <div className="color-error">{errors.D7hotel1url}</div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                    <br />
                    <h2 className="text-center md:col-span-2 title-form">
                      Hotel #2
                    </h2>
                    <div className="col-span-1 m-3">
                      <Label>Hotel #2 Address</Label>
                      <Input
                        type="text"
                        name="D7hotel2lugar"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel2lugar}
                        className={errors.D7hotel2lugar ? "input-error" : ""}
                      />
                      {touched.D7hotel2lugar && errors.D7hotel2lugar && (
                        <div className="color-error">
                          {errors.D7hotel2lugar}
                        </div>
                      )}
                    </div>
                    <div className="col-span-1 m-3">
                      <Label>Hotel #2 Location Link</Label>
                      <Input
                        type="text"
                        name="D7hotel2url"
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        value={values.D7hotel2url}
                        className={errors.D7hotel2url ? "input-error" : ""}
                      />
                      {touched.D7hotel2url && errors.D7hotel2url && (
                        <div className="color-error">{errors.D7hotel2url}</div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <br />
              {dataEvento?.enableRegalos ? (
                <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                  <h2 className="text-center md:col-span-2 title-form">
                    GIFT REGISTRY
                  </h2>
                  <div className="col-span-1 m-3">
                    <Label>Link for 1st Registry</Label>
                    <Input
                      type="text"
                      name="D8linkRegalo1"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D8linkRegalo1}
                      className={errors.D8linkRegalo1 ? "input-error" : ""}
                    />
                    {touched.D8linkRegalo1 && errors.D8linkRegalo1 && (
                      <div className="color-error">{errors.D8linkRegalo1}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <Label>Option #2 Link</Label>
                    <Input
                      type="text"
                      name="D8linkRegalo2"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D8linkRegalo2}
                      className={errors.D8linkRegalo2 ? "input-error" : ""}
                    />
                    {touched.D8linkRegalo2 && errors.D8linkRegalo2 && (
                      <div className="color-error">{errors.D8linkRegalo2}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <Label>Option #3 Link</Label>
                    <Input
                      type="text"
                      name="D8linkRegalo3"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.D8linkRegalo3}
                      className={errors.D8linkRegalo3 ? "input-error" : ""}
                    />
                    {touched.D8linkRegalo3 && errors.D8linkRegalo3 && (
                      <div className="color-error">{errors.D8linkRegalo3}</div>
                    )}
                  
                  </div>
                </div>
              ) : (
                ""
              )}
              <br />
              {dataEvento?.enablePrograma ? (
                <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                  <h2 className="text-center md:col-span-2 title-form">
                    SCHEDULE
                  </h2>
                  <div className="col-span-1 m-3">
                    <Label>Section 1</Label>
                    <Input
                      type="text"
                      name="PceremoniaReligiosaTitulo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PceremoniaReligiosaTitulo}
                      className={
                        errors.PceremoniaReligiosaTitulo ? "input-error" : ""
                      }
                    />
                    {touched.PceremoniaReligiosaTitulo &&
                      errors.PceremoniaReligiosaTitulo && (
                        <div className="color-error">
                          {errors.PceremoniaReligiosaTitulo}
                        </div>
                      )}
                  </div>
                  <div className="col-span-1 m-3">
                    <textarea
                      type="text"
                      name="PceremoniaReligiosa"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PceremoniaReligiosa}
                      className={
                        errors.PceremoniaReligiosa
                          ? "input-error w-full"
                          : "w-full"
                      }
                    />
                    {touched.PceremoniaReligiosa &&
                      errors.PceremoniaReligiosa && (
                        <div className="color-error">
                          {errors.PceremoniaReligiosa}
                        </div>
                      )}
                  </div>

                  <div className="col-span-1 m-3">
                    <Label>Section 2</Label>
                    <Input
                      type="text"
                      name="PrecepcionTitulo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PrecepcionTitulo}
                      className={errors.PrecepcionTitulo ? "input-error" : ""}
                    />
                    {touched.PrecepcionTitulo && errors.PrecepcionTitulo && (
                      <div className="color-error">
                        {errors.PrecepcionTitulo}
                      </div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <textarea
                      type="text"
                      name="Precepcion"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.Precepcion}
                      className={
                        errors.Precepcion ? "input-error w-full" : "w-full"
                      }
                    />
                    {touched.Precepcion && errors.Precepcion && (
                      <div className="color-error">{errors.Precepcion}</div>
                    )}
                  </div>

                  <div className="col-span-1 m-3">
                    <Label>Section 3</Label>
                    <Input
                      type="text"
                      name="PbaileXvTitulo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PbaileXvTitulo}
                      className={errors.PbaileXvTitulo ? "input-error" : ""}
                    />
                    {touched.PbaileXvTitulo && errors.PbaileXvTitulo && (
                      <div className="color-error">{errors.PbaileXvTitulo}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <textarea
                      type="text"
                      name="PbaileXv"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PbaileXv}
                      className={
                        errors.PbaileXv ? "input-error w-full" : "w-full"
                      }
                    />
                    {touched.PbaileXv && errors.PbaileXv && (
                      <div className="color-error">{errors.PbaileXv}</div>
                    )}
                  </div>

                  <div className="col-span-1 m-3">
                    <Label>Section 4</Label>
                    <Input
                      type="text"
                      name="PcenaTitulo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PcenaTitulo}
                      className={errors.PcenaTitulo ? "input-error" : ""}
                    />
                    {touched.PcenaTitulo && errors.PcenaTitulo && (
                      <div className="color-error">{errors.PcenaTitulo}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <textarea
                      type="text"
                      name="Pcena"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.Pcena}
                      className={errors.Pcena ? "input-error w-full" : "w-full"}
                    />
                    {touched.Pcena && errors.Pcena && (
                      <div className="color-error">{errors.Pcena}</div>
                    )}
                  </div>

                  <div className="col-span-1 m-3">
                    <Label>Section 5</Label>
                    <Input
                      type="text"
                      name="PcoctelTitulo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PcoctelTitulo}
                      className={errors.PcoctelTitulo ? "input-error" : ""}
                    />
                    {touched.PcoctelTitulo && errors.PcoctelTitulo && (
                      <div className="color-error">{errors.PcoctelTitulo}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <textarea
                      type="text"
                      name="Pcoctel"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.Pcoctel}
                      className={
                        errors.Pcoctel ? "input-error w-full" : "w-full"
                      }
                    />
                    {touched.Pcoctel && errors.Pcoctel && (
                      <div className="color-error">{errors.Pcoctel}</div>
                    )}
                  </div>

                  <div className="col-span-1 m-3">
                    <Label>Section 6</Label>
                    <Input
                      type="text"
                      name="PbaileTitulo"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.PbaileTitulo}
                      className={errors.PbaileTitulo ? "input-error" : ""}
                    />
                    {touched.PbaileTitulo && errors.PbaileTitulo && (
                      <div className="color-error">{errors.PbaileTitulo}</div>
                    )}
                  </div>
                  <div className="col-span-1 m-3">
                    <textarea
                      type="text"
                      name="Pbaile"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.Pbaile}
                      className={
                        errors.Pbaile ? "input-error w-full" : "w-full"
                      }
                    />
                    {touched.Pbaile && errors.Pbaile && (
                      <div className="color-error">{errors.Pbaile}</div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <br />

              {dataEvento?.enableVestimenta ? (
                <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                  <h2 className="text-center md:col-span-2 title-form">
                    DRESS CODE
                  </h2>
                  <div className="col-span-1 m-3">
                    <Label>Dresscode</Label>
                    <Input
                      type="text"
                      name="Vestimenta"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.Vestimenta}
                      placeholder="Formal.."
                      className={errors.Vestimenta ? "input-error" : ""}
                    />
                    {touched.Vestimenta && errors.Vestimenta && (
                      <div className="color-error">{errors.Vestimenta}</div>
                    )}
                  </div>

                  <div className="col-span-1 m-3">
                    <Label>Dresscode Notice</Label>
                    <Input
                      type="text"
                      name="VestimentaAviso"
                      onChange={(e) => { handleChange(e); handleInputChange(e); }}
                      value={values.VestimentaAviso}
                      placeholder="Please wear light colors.."
                      className={errors.VestimentaAviso ? "input-error" : ""}
                    />
                    {touched.VestimentaAviso && errors.VestimentaAviso && (
                      <div className="color-error">
                        {errors.VestimentaAviso}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <br />
              <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                <h2 className="text-center md:col-span-2 title-form">
                  Background Song
                </h2>
                <br />
                <div className="col-span-2 m-3">
                  <Label>Song</Label>
                  <Input
                    type="text"
                    name="cancion"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.cancion}
                    placeholder=""
                    className={errors.cancion ? "input-error" : ""}
                  />
                  {touched.cancion && errors.cancion && (
                    <div className="color-error">{errors.cancion}</div>
                  )}
                </div>
                <div className="col-span-2 m-3">
                  <Label>Tutorial for getting song link</Label>
                  <img
                    src="https://i.postimg.cc/DyznQv2p/0605-1.gif"
                    className="w-full h-[30vh]"
                  />
                </div>
                {dataEvento?.paquete !== "Plata" ? (
                  <>
                    <h2 className="text-center md:col-span-2 title-form">
                      FONT
                    </h2>
                    <br />
                    <div className="col-span-2 m-3">
                      <Label>Font</Label>
                      <select
                        id="tipoLetra"
                        name="tipoLetra"
                        value={values?.tipoLetra}
                        onChange={(e) => { handleChange(e); handleInputChange(e); }}
                        className="w-full p-2 border border-gray-300 rounded"
                        style={{
                          fontFamily: values?.tipoLetra || "Arial, sans-serif",
                        }}
                      >
                        <option value="">Select a font</option>
                        <option
                          value="Playwrite AR, cursive"
                          style={{ fontFamily: "Playwrite AR, cursive" }}
                        >
                          Playwrite AR
                        </option>
                        <option
                          value="Arial, sans-serif"
                          style={{ fontFamily: "Arial, sans-serif" }}
                        >
                          Arial
                        </option>
                        <option
                          value="Times New Roman, serif"
                          style={{ fontFamily: "Times New Roman, serif" }}
                        >
                          Times New Roman
                        </option>
                        <option
                          value="Courier New, monospace"
                          style={{ fontFamily: "Courier New, monospace" }}
                        >
                          Courier New
                        </option>
                        <option
                          value="Georgia, serif"
                          style={{
                            fontFamily:
                              "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
                          }}
                        >
                          Georgia
                        </option>
                        <option
                          value="Verdana, sans-serif"
                          style={{ fontFamily: "Verdana, sans-serif" }}
                        >
                          Verdana
                        </option>
                        <option
                          value="Tangerine, cursive"
                          style={{ fontFamily: "Tangerine, cursive" }}
                        >
                          Tangerine
                        </option>
                        {/* Añade más opciones aquí según sea necesario "Ibarra Real Nova", serif*/}
                      </select>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <br />
              <div className="grid w-full md:grid-cols-2 sm:grid-cols-1">
                <br />
                <h2 className="text-center md:col-span-2 title-form">
                  Comments or additional notes for us
                </h2>
                <div className="md:col-span-2 m-3">
                  <Label>Details</Label>
                  <br />
                  <textarea
                    type="text"
                    name="Detalles"
                    onChange={(e) => { handleChange(e); handleInputChange(e); }}
                    value={values.Detalles}
                    className={`w-full h-[20vh] ${
                      errors.Detalles ? "input-error" : ""
                    }`}
                  />
                  {touched.Detalles && errors.Detalles && (
                    <div className="color-error">{errors.Detalles}</div>
                  )}
                </div>
              </div>

              {isOpen && (
                <ModalFormularioXvPre
                  isOpen={isOpen}
                  toggleModal={toggleModal}
                  datos={values}
                  dataEvento={dataEvento}
                  ingles={true}
                />
              )}

              <CarouselComponent values={values} setValues={setFieldValue} ingles={true} paquete={dataEvento?.paquete}/>

              <br />
              <br />
              <div className="mb-4">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  See invite sample (For photos and background)
                </button>
              </div>
              <br />
              <br />
              <div className="mb-4">
                <button
                  type="submit"
                  disabled={loadingSend}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Finish
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
