import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { baseChartOptions } from '../types/chartOptions';

const VentasMensuales = ({ selectedYear }) => {
  const [dataMensual, setDataMensual] = useState(null);

  useEffect(() => {
    const fetchVentasMensuales = async () => {
      try {
        const response = await axios.get("/api/Eventos/ventas-mensuales", {
          params: { year: selectedYear },
        });
        setDataMensual(response.data);
      } catch (error) {
        console.error("Error fetching monthly sales data", error);
      }
    };

    fetchVentasMensuales();
  }, [selectedYear]);

  if (!dataMensual) return <div>Cargando...</div>;

  const chartData = {
    labels: dataMensual.map(d => `Mes ${d.mes}`),
    datasets: [
      {
        label: "Ventas Mensuales",
        data: dataMensual.map(d => ({
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
        })),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 h-[500px]">
      <div className="h-[400px]">
        <Bar data={chartData} options={baseChartOptions} />
      </div>
    </div>
  );
};

export default VentasMensuales; 