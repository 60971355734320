import { BodaEjemplo } from "../screens/bodas/Boda-diamante/BodaEjemplo";
import { BodaEjemplo2 } from "../screens/bodas/Boda-diamante/BodaEjemplo2";
import { BodaEjemploPortada2 } from "../screens/bodas/Boda-diamante/BodaEjemploPortada2";
import { BodaGloriaYEfrain } from "../screens/bodas/Boda-diamante/BodaGloriaYEfrain";
import { BodaEjemploNew } from "../screens/bodas/Boda-diamante/BodaEjemploNew";
import { BodaEjemploSinFoto } from "../screens/bodas/Boda-diamante/BodaEjemploSinFoto";

const RoutesBodaDiamante = [
  {
    path: "/boda-diamante-ejemplo",
    element: <BodaEjemplo />,
  },
  {
    path: "/boda-diamante-ejemplo-fotos",
    element: <BodaEjemploSinFoto />,
  },
  {
    path: "/boda-diamante-ejemplo2",
    element: <BodaEjemplo2 />,
  },
  {
    path: "/boda-diamante-ejemplo3",
    element: <BodaEjemploPortada2 />,
  },
  {
    path: "/boda-diamante-ejemplo-new",
    element: <BodaEjemploNew />,
  },
  {
    path: "/boda-diamante-gloria&efrain",
    element: <BodaGloriaYEfrain />,
  },
];

export default RoutesBodaDiamante;
