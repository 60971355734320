import React from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';

export const ColoresEventoForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Colores del Evento</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="color1" className="block text-sm font-medium text-gray-700 mb-1">
                    Color Principal:
                  </label>
                  <Input
                    type="color"
                    id="color1"
                    name="color1"
                    value={values?.color1}
                    onChange={handleChange}
                    className="w-full h-10 p-1 rounded-md border-gray-300"
                  />
                </div>
                <div>
                  <label htmlFor="color2" className="block text-sm font-medium text-gray-700 mb-1">
                    Color Secundario:
                  </label>
                  <Input
                    type="color"
                    id="color2"
                    name="color2"
                    value={values?.color2}
                    onChange={handleChange}
                    className="w-full h-10 p-1 rounded-md border-gray-300"
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Actualizar Colores
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 