import { BautizoAutomaticoTrillizas } from "../screens/XV/xv-automatizado/BautizoAutomaticoTrillizas"
import { CumpleManuel } from "../screens/XV/xv-automatizado/CumpleManuel"
import { XvAutomatica } from "../screens/XV/xv-automatizado/XvAutomatica"
import { XvAutomatica4Regalos } from "../screens/XV/xv-automatizado/XvAutomatica4Regalos"
import { XvAutomaticaDiamante } from "../screens/XV/xv-automatizado/XvAutomaticaDiamante"
import { XvAutomaticaDiseño2 } from "../screens/XV/xv-automatizado/XvAutomaticaDiseño2"
import { XvAutomaticaProg } from "../screens/XV/xv-automatizado/XvAutomaticaProg"
import { XvAutomatica as XvAutomaticaEn } from "../screens/XV/xv-automatizado/ingles/XvAutomatica"
import { XvAutomaticaDiamante as XvAutomaticaDiamanteEn } from "../screens/XV/xv-automatizado/ingles/XvAutomaticaDiamante"
import { EjemplosXvEs } from "../screens/Admin/EjemplosPage/Español/EjemplosXvEs"
import { EjemplosXvCo } from "../screens/Admin/EjemplosPage/Español/EjemplosXvCo"
import { EjemplosBodaEs } from "../screens/Admin/EjemplosPage/Español/EjemplosBodaEs"
import { EjemplosBodaCo } from "../screens/Admin/EjemplosPage/Español/EjemplosBodaCo"
import HomeEs from "../components/HomeEs"
import HomeCo from "../components/HomeCo"
import { XvAutomaticaDiamante2 } from "../screens/XV/xv-automatizado/XvAutomaticaDiamante2"
import { XvAutomatica2 } from "../screens/XV/xv-automatizado/XvAutomatica2"
import { FormularioAutoXvEn } from "../screens/Admin/FormsComponent/ingles/FormularioAutoXVEn"

const RutaAutomaticaXv = [
    {
        path: '/xv-auto',
        element: <XvAutomatica />
    },
    {
        path: '/event-form',
        element: <FormularioAutoXvEn />
    },
    {
        path: '/2/evento-auto',
        element: <XvAutomatica2 />
    },
    {
        path: '/en/event-auto',
        element: <XvAutomaticaEn />
    },
    {
        path: '/xv-auto-mr',
        element: <XvAutomatica4Regalos />
    },
    {
        path: '/xv-auto-porg',
        element: <XvAutomaticaProg />
    },
    {
        path: '/xv-d2-auto',
        element: <XvAutomaticaDiseño2 />
    },
    {
        path: '/XV-auto-diamante',
        element: <XvAutomaticaDiamante />
    },
    {
        path: '/2/xv-auto-diamante',
        element: <XvAutomaticaDiamante2 />
    },
    {
        path: '/2/diamond-event',
        element: <XvAutomaticaDiamante2 />
    },
    {
        path: '/event-auto-diamond',
        element: <XvAutomaticaDiamanteEn />
    },
    {
        path: '/bautizo-ejemplo-auto',
        element: <BautizoAutomaticoTrillizas />
    },
    {
        path: '/cumple-ejemplo-auto',
        element: <CumpleManuel />
    },
    {
        path: '/ejemplo-boda',
        element: <EjemplosBodaEs />
    }, 
    {
        path: '/ejemplo-boda-co',
        element: <EjemplosBodaCo />
    }, 
    {
        path: '/ejemplo-xv',
        element: <EjemplosXvEs />
    }, 
    {
        path: '/ejemplo-xv-co',
        element: <EjemplosXvCo />
    }, 
    {
        path: '/co',
        element: <HomeCo />
    },  
    {
        path: '/es',
        element: <HomeEs />
    },  
]

export default RutaAutomaticaXv
