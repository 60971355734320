import React, { useEffect, useState } from "react"
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import { QRCodeSVG } from 'qrcode.react'

export const InvitadosBodaComponent2 = ({ invitado, xv, datosSeccionInvitados, colores, dataInvitado, isEnglish }) => {
  const [showModal, setShowModal] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [asistencia, setAsistencia] = useState(dataInvitado?.link || '');
  const [mensaje, setMensaje] = useState(dataInvitado?.mensajeInvitado || '');

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    })
    console.log(dataInvitado);
  }, [])

  const fontFamilyStyle = {
    fontFamily: datosSeccionInvitados?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: '#ffffff'
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const invitadoActualizado = {
        accesos:dataInvitado.accesos,
        idEvento:dataInvitado.idEvento,
        idEventoNavigation:null,
        idInvitado:dataInvitado.idInvitado,
        link:asistencia,
        mensajeInvitado:mensaje,
        nombre:dataInvitado.nombre
      };

      await axios.put(`/api/Invitado/${dataInvitado.idInvitado}`, invitadoActualizado);

      setShowModal(false);
      setShowConfirmation(true);
      setTimeout(() => {
        setShowConfirmation(false);
      }, 3000);
    } catch (error) {
      console.error("Error updating guest:", error);
      alert(isEnglish ? "There was an error sending your confirmation" : "Hubo un error al enviar tu confirmación");
    }
  };

  const qrValue = `confirmacion-evento-${dataInvitado?.idInvitado}`;

  return (
    <div className="min-h-screen relative flex items-center justify-center p-4"
      style={{ backgroundColor: colores?.color1 || '#f5f0e9' }}
    >
      <div className="absolute inset-0">
        <img
          src={datosSeccionInvitados?.imgFondo || "https://img.freepik.com/foto-gratis/retrato-feliz-pareja-casada-guapo-novio-elegante-traje-gris-abrazando-novia-vestido-novia-ramo-mientras-posa-jardin-ceremonia-dia-boda_8353-11963.jpg"}
          alt="Wedding background"
          className="w-full h-full object-cover opacity-80"
        />
      </div>
      <div
        data-aos="fade-up"
        className="max-w-sm w-full backdrop-blur-md rounded-2xl px-6 py-8 text-center shadow-lg relative z-10"
        style={{
          backgroundColor: '#b7b7b726'
        }}
      >
        <div className="space-y-6">
          <h1
            data-aos="fade-down"
            data-aos-delay="200"
            className="text-4xl text-[#ffffff]"
            style={textStyle}
          >
            {isEnglish ? (datosSeccionInvitados?.titulo || "You're Invited") : (datosSeccionInvitados?.titulo || "Están Invitados")}
          </h1>

          <div
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h2 className="text-3xl mb-1" style={textStyle}>
              {dataInvitado?.nombre || (isEnglish ? "Guest" : "Invitado")}
            </h2>
            <p className="text-lg" style={textStyle}>{isEnglish ? "We're waiting for you!" : "¡Los esperamos!"}</p>
          </div>

          <div
            data-aos="zoom-in"
            data-aos-delay="600"
            className="inline-flex items-center justify-center w-20 h-20 rounded-full border-3 border-[#8B7355]"
          >
            <span className="text-3xl" style={textStyle}>{invitado || "1"}</span>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="800"
          >
            <p className="text-lg" style={textStyle}>
              {isEnglish ? `Invitation valid for ${invitado} PASSES` : `Invitación válida por ${invitado} PASES`}
            </p>
            <p className="mt-4 text-base" style={textStyle}>
              {datosSeccionInvitados?.aviso?.split("\\n").map((linea, index) => (
                <React.Fragment key={index}>
                  {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
                    parte.startsWith("/") && parte.endsWith("/") ? (
                      <strong key={subIndex}>{parte.slice(1, -1)}</strong>
                    ) : (
                      <span key={subIndex}>{parte}</span>
                    )
                  ))}
                  <br />
                </React.Fragment>
              )) || ""}
            </p>

            <div className="flex flex-col space-y-4 mt-6">
              <button
                onClick={() => setShowModal(true)}
                className="px-6 py-3 text-white rounded-lg hover:bg-[#6d5a42] transition-colors duration-300"
                style={{ ...fontFamilyStyle, backgroundColor: colores?.color2 || '#8B7355' }}
              >
                {isEnglish ? "Confirm your attendance" : "Confirma tu asistencia"}
              </button>

              <button
                onClick={() => setShowQRModal(true)}
                className="px-6 py-3 text-white rounded-lg hover:bg-[#6d5a42] transition-colors duration-300"
                style={{ ...fontFamilyStyle, backgroundColor: colores?.color2 || '#8B7355' }}
              >
                {isEnglish ? "Show QR Code" : "Mostrar Código QR"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className="bg-white rounded-lg p-8 max-w-md w-full transform transition-all duration-500 ease-in-out"
            data-aos="zoom-in"
          >
            <h3 className="text-2xl font-semibold mb-4" style={fontFamilyStyle}>
              {isEnglish ? "Confirm your attendance" : "Confirma tu asistencia"}
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {isEnglish ? "Will you attend the event?" : "¿Asistirás al evento?"}
                </label>
                <select
                  value={asistencia}
                  onChange={(e) => setAsistencia(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8B7355]"
                  required
                >
                  <option value="">{isEnglish ? "Select an option" : "Selecciona una opción"}</option>
                  <option value="si">{isEnglish ? "Yes, I will attend" : "Sí asistiré"}</option>
                  <option value="no">{isEnglish ? "No, I won't attend" : "No asistiré"}</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {xv ? (isEnglish ? "Message for the birthday girl" : "Mensaje para la persona festejada") : (isEnglish ? "Message for the couple" : "Mensaje para los novios")}
                </label>
                <textarea
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#8B7355]"
                  rows="4"
                  placeholder={isEnglish ? "Write a special message..." : "Escribe un mensaje especial..."}
                  required
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  {isEnglish ? "Cancel" : "Cancelar"}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#8B7355] text-white rounded-lg hover:bg-[#6d5a42]"
                >
                  {isEnglish ? "Send" : "Enviar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showQRModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className="bg-white rounded-lg p-8 max-w-md w-full transform transition-all duration-500 ease-in-out text-center"
            data-aos="zoom-in"
          >
            <h3 className="text-2xl font-semibold mb-4" style={fontFamilyStyle}>
              {isEnglish ? "Your QR Code" : "Tu Código QR"}
            </h3>
            <div className="flex justify-center mb-4">
              <QRCodeSVG 
                value={qrValue}
                size={256}
                level="H"
                includeMargin={true}
                bgColor="#FFFFFF"
                fgColor="#000000"
              />
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {isEnglish ? "Use this QR code to validate your invitation when you arrive at the event." : "Usa este código QR para validar tu invitación al momento de llegar al evento."}
            </p>
            <button
              onClick={() => setShowQRModal(false)}
              className="px-6 py-2 bg-[#272727c7] text-white rounded-lg hover:bg-[#272727c7]"
            >
              {isEnglish ? "Close" : "Cerrar"}
            </button>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div
          className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg z-50 transform transition-all duration-500 ease-in-out"
          data-aos="fade-left"
        >
          <p className="font-semibold">
            {isEnglish ? "Thank you! Your confirmation has been sent successfully." : "¡Gracias! Tu confirmación ha sido enviada exitosamente."}
          </p>
        </div>
      )}
    </div>
  )
}
