import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const ContadorBodaComponent2 = ({ contadorData, peltaColores, isEnglish }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const fontFamilyStyle = {
    fontFamily: contadorData.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    });
  }, []);

  // Format date as "Saturday March 16, 2024" or "Sábado 16 de Marzo de 2024"
  const formatWeddingDate = (fechaISO) => {
    const date = new Date(fechaISO);

    // Check if valid
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const daysOfWeek = isEnglish ? [
      "Sunday",
      "Monday",
      "Tuesday", 
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ] : [
      "Domingo",
      "Lunes", 
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const months = isEnglish ? [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ] : [
      "Enero",
      "Febrero", 
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const day = daysOfWeek[date.getDay()];
    const dayNumber = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return isEnglish ? 
      `${day} ${month} ${dayNumber}, ${year}` :
      `${day} ${dayNumber} de ${month} de ${year}`;
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const weddingDate = new Date(contadorData.fechaDeBoda);
      const now = new Date();
      const difference = weddingDate.getTime() - now.getTime();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [contadorData.fechaDeBoda]);

  const addToCalendar = () => {
    const event = {
      title: contadorData.titulo || (isEnglish ? "Our Wedding" : "Nuestra Boda"),
      description: contadorData.descipcion || (isEnglish ? "Our wedding celebration" : "Celebración de nuestra boda"),
      location: isEnglish ? "Ceremony location" : "Lugar de la ceremonia",
      startTime: contadorData.fechaDeBoda,
      endTime: contadorData.fechaNewDate || contadorData.fechaDeBoda,
    };

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.title
    )}&details=${encodeURIComponent(event.description)}&location=${encodeURIComponent(
      event.location
    )}&dates=${event.startTime.replace(/[-:]/g, "").split(".")[0]}/${
      event.endTime.replace(/[-:]/g, "").split(".")[0]
    }`;

    window.open(googleCalendarUrl, "_blank");
  };

  const renderFormattedText = (text) => {
    if (!text) return null;
    return text.split("\\n").map((linea, index) => (
      <React.Fragment key={index}>
        {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
          parte.startsWith("/") && parte.endsWith("/") ? (
            <strong key={subIndex}>{parte.slice(1, -1)}</strong>
          ) : (
            <span key={subIndex}>{parte}</span>
          )
        ))}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="relative min-[100px] py-6 px-2 justify-center items-center flex w-full bg-[#f5f0e9]" >
        <h1 
          data-aos="fade-down"
          style={{...fontFamilyStyle, color: contadorData.textColor || '#000000'}}
          className="text-xl mb-2"
        >
          {renderFormattedText(contadorData.descipcion)}
        </h1>
      </div>
      <div className="relative min-h-screen bg-[#f5f0e9]">
        {/* Main Image */}
        <div className="relative h-screen">
          <img
            data-aos="fade-in"
            src={contadorData.imgFondo || ""}
            alt={isEnglish ? "Wedding photo" : "Foto de la boda"}
            className="w-full h-full object-cover"
          />

          {/* Bottom Overlay */}
          <div className="absolute bottom-0 left-0 right-0">
            <div className="relative">
              {/* Semi-circle background */}
              <div 
                data-aos="fade-up"
                className="absolute bottom-0 left-0 right-0 h-[380px] rounded-t-full" 
                style={{backgroundColor: `${peltaColores?.color1}b3` || '#f5f0e9'}}
              />

              {/* Content */}
              <div className="relative px-6 pb-8 pt-16 text-center">
                <h1 
                  data-aos="fade-up"
                  data-aos-delay="200"
                  style={{...fontFamilyStyle, color: contadorData.textColor || '#000000'}}
                  className="text-4xl mb-2"
                >
                  {renderFormattedText(contadorData.titulo || (isEnglish ? "We're Getting Married" : "Nos Casamos"))}
                </h1>
                <p 
                  data-aos="fade-up"
                  data-aos-delay="300"
                  style={{...fontFamilyStyle, color: contadorData.textColor || '#000000'}}
                  className="text-xl mb-8"
                >
                  {formatWeddingDate(contadorData.fechaDeBoda)}
                </p>

                {/* Countdown */}
                <div className="flex justify-center gap-4 mb-8">
                  <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    className="w-20 h-20 rounded-lg flex flex-col items-center justify-center"
                    style={{ color: '#ffffff', backgroundColor: peltaColores?.color2 || '#b5a99a' }}
                  >
                    <span style={fontFamilyStyle} className="text-2xl font-bold">{timeLeft.days}</span>
                    <span style={fontFamilyStyle} className="text-sm">{isEnglish ? "Days" : "Días"}</span>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="500"
                    className="w-20 h-20 rounded-lg flex flex-col items-center justify-center"
                    style={{ color: '#ffffff', backgroundColor: peltaColores?.color2 || '#b5a99a' }}
                  >
                    <span style={fontFamilyStyle} className="text-2xl font-bold">
                      {String(timeLeft.hours).padStart(2, "0")}
                    </span>
                    <span style={fontFamilyStyle} className="text-sm">{isEnglish ? "Hrs" : "Hrs"}</span>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="600"
                    className="w-20 h-20 rounded-lg flex flex-col items-center justify-center"
                    style={{ color: '#ffffff', backgroundColor: peltaColores?.color2 || '#b5a99a' }}
                  >
                    <span style={fontFamilyStyle} className="text-2xl font-bold">
                      {String(timeLeft.minutes).padStart(2, "0")}
                    </span>
                    <span style={fontFamilyStyle} className="text-sm">{isEnglish ? "Mins" : "Mins"}</span>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="700"
                    className="w-20 h-20 rounded-lg flex flex-col items-center justify-center"
                    style={{ color: '#ffffff', backgroundColor: peltaColores?.color2 || '#b5a99a' }}
                  >
                    <span style={fontFamilyStyle} className="text-2xl font-bold">
                      {String(timeLeft.seconds).padStart(2, "0")}
                    </span>
                    <span style={fontFamilyStyle} className="text-sm">{isEnglish ? "Secs" : "Segs"}</span>
                  </div>
                </div>

                {/* Add to Calendar Button */}
                <button
                  data-aos="fade-up"
                  data-aos-delay="800"
                  onClick={addToCalendar}
                  style={{...fontFamilyStyle, color:'#ffffff', backgroundColor: peltaColores?.color2 || '#b5a99a'}}
                  className="flex items-center justify-center gap-2 mx-auto px-6 py-3 bg-[#b5a99a] rounded-full hover:bg-[#9a8e80] transition-colors duration-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                    <line x1="16" y1="2" x2="16" y2="6" />
                    <line x1="8" y1="2" x2="8" y2="6" />
                    <line x1="3" y1="10" x2="21" y2="10" />
                  </svg>
                  {isEnglish ? "Add to Calendar" : "Agregar a Calendario"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
