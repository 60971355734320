// Importa los estilos de Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ImageFormComponent as ImageForm } from '../FormsComponent/Images/ImageFormComponent'
import { CarouselComponent } from '../FormsComponent/Images/CarouselComponent'
import toast from "react-hot-toast";
import './ModalImages.css'

export const ModalEditarImagenes = ({
    modalImages,
    setModalImages,
    infoImages,
    edit
}) => {
    const toggleModalImages = () => setModalImages(false);

    // Esquema de validación usando Yup
    const fileValidation = Yup.mixed()
        .test({
            message: 'Please provide a supported file type',
            test: (file, context) => {
                if(typeof(file) == 'undefined')
                    return true;
                const isValid = ['png', 'jpg', 'jpeg', 'bmp', 'tiff'].includes(file?.name.split('.').pop());
                if (!isValid) context?.createError();
                return isValid;
            }
        });

    const validationSchema = Yup.object().shape({
        eventId: Yup.number(),
        ceremonyImg: Yup.string().nullable(true),
        ceremonyReceptionImg: Yup.string().nullable(true),
        ceremonyBackgroundImg: Yup.string().nullable(true),

        confirmationBackgroundImg: Yup.string().nullable(true),

        counterBackgroundImg: Yup.string().nullable(true),
        counterLeftImg: Yup.string().nullable(true),
        counterRightImg: Yup.string().nullable(true),

        hostingBackgroundImg: Yup.string().nullable(true),

        inviteBackgroundImg: Yup.string().nullable(true),

        instaBackgroundImg: Yup.string().nullable(true),

        parentsBackgroundImg: Yup.string().nullable(true),

        coverBackgroundImg: Yup.string().nullable(true),

        weddingCoverBackgroundImg: Yup.string().nullable(true),

        weddingProgramBackgroundImg: Yup.string().nullable(true),
        weddingProgramDinnerImg: Yup.string().nullable(true),
        weddingProgramCoctailImg: Yup.string().nullable(true),
        weddingProgramReceptionImg: Yup.string().nullable(true),
        weddingProgramCeremonyImg: Yup.string().nullable(true),
        weddingProgramDanceImg: Yup.string().nullable(true),
        weddingProgramCivilImg: Yup.string().nullable(true),

        programBackgroundImg: Yup.string().nullable(true),
        programDinnerImg: Yup.string().nullable(true),
        programCoctailImg: Yup.string().nullable(true),
        programReceptionImg: Yup.string().nullable(true),
        programCeremonyImg: Yup.string().nullable(true),
        programXVImg: Yup.string().nullable(true),
        programDanceImg: Yup.string().nullable(true),

        giftsBackgroundImg: Yup.string().nullable(true),

        dressingCodeBackgroundImg: Yup.string().nullable(true),

        ceremonyFile: fileValidation,
        ceremonyReceptionFile: fileValidation,
        ceremonyBackgroundFile: fileValidation,

        confirmationBackgroundFile: fileValidation,

        counterBackgroundFile: fileValidation,
        counterLeftFile: fileValidation,
        counterRightFile: fileValidation,

        hostingBackgroundFile: fileValidation,

        inviteBackgroundFile: fileValidation,

        instaBackgroundFile: fileValidation,

        ParentsBackgroundFile: fileValidation,

        CoverBackgroundFile: fileValidation,

        weddingCoverBackgroundFile: fileValidation,

        weddingProgramBackgroundFile: fileValidation,
        weddingProgramDinnerFile: fileValidation,
        weddingProgramCoctailFile: fileValidation,
        weddingProgramReceptionFile: fileValidation,
        weddingProgramCeremonyFile: fileValidation,
        weddingProgramDanceFile: fileValidation,
        weddingProgramCivilFile: fileValidation,

        programBackgroundFile: fileValidation,
        programDinnerFile: fileValidation,
        programCoctailFile: fileValidation,
        programReceptionFile: fileValidation,
        programCeremonyFile: fileValidation,
        programXVFile: fileValidation,
        programDanceFile: fileValidation,

        giftsBackgroundFile: fileValidation,

        dressingCodeBackgroundFile: fileValidation
    });

    const defaultValues = {
        eventId: "",
        ceremonyImg: "",
        ceremonyReceptionImg: "",
        ceremonyBackgroundImg: "",

        confirmationBackgroundImg: "",

        counterBackgroundImg: "",
        counterLeftImg: "",
        counterRightImg: "",

        hostingBackgroundImg: "",

        inviteBackgroundImg: "",

        instaBackgroundImg: "",

        parentsBackgroundImg: "",

        coverBackgroundImg: "",

        weddingCoverBackgroundImg: "",

        weddingProgramBackgroundImg: "",
        weddingProgramDinnerImg: "",
        weddingProgramCoctailImg: "",
        weddingProgramReceptionImg: "",
        weddingProgramCeremonyImg: "",
        weddingProgramDanceImg: "",
        weddingProgramCivilImg: "",

        programBackgroundImg: "",
        programDinnerImg: "",
        programCoctailImg: "",
        programReceptionImg: "",
        programCeremonyImg: "",
        programXVImg: "",
        programDanceImg: "",

        giftsBackgroundImg: "",

        dressingCodeBackgroundImg: "",

        carousel: [],

        hasCeremony: false,
        hasConfirmation: false,
        hasCounters: false,
        hasHostings: false,
        hasInvites: false,
        hasParents: false,
        hasCover: false,
        hasWeddingCover: false,
        hasWeddingProgram: false,
        hasProgram: false,
        hasGifts: false,
        hasDressingCode: false
    };

    // Función para enviar los datos del formulario
    const enviarDatos = async (values) => {
        toast.loading(`Enviando informacion`)
        console.log("edit:", values);
        try {
            let data = new FormData();
            for(let value in values){
                if(value == "carousel"){
                    values[value].forEach((x, index) => {
                        data.append(`${value}[${index}].Id`,            x.id);
                        data.append(`${value}[${index}].Url`,           x.url);
                        data.append(`${value}[${index}].Replacement`,   x.replacement);
                        data.append(`${value}[${index}].Deleted`,       x.deleted);
                    });
                    
                }
                else {
                    data.append(value, values[value]);
                }
            }

            // Realizar la solicitud PUT a la URL especificada con los datos del formulario
            const response = await axios.post(
                `/api/Images`,
                data
            );

            // Manejar la respuesta de la solicitud
            console.log("Respuesta del servidor:", response.data);

            // Cerrar el modal después de enviar los datos
            toggleModalImages();
            toast.dismiss();
            toast.success("Se ha enviado");
        } catch (error) {
            toast.dismiss();
            toast.success("Erro"+error);
            // Manejar errores en caso de que la solicitud falle
            console.error("Error al enviar los datos:", error);
        }
    };

    console.log("Info del edit", infoImages);

    return (
        <Modal
            className="modal-md"
            isOpen={modalImages}
            toggle={toggleModalImages}
            backdropOpacity={0.5}
        >
            <ModalHeader toggle={toggleModalImages}>Editar Evento</ModalHeader>
            <Formik
                
                id={"imgForm"}
                initialValues={infoImages?? defaultValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    enviarDatos(values);
                }}
            >
                {({ values, handleChange, handleSubmit, errors, touched, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} >
                        <ModalBody>
                            <FormGroup>
                                <Label for="nombre">ID de Evento</Label>
                                <Input
                                    type="text"
                                    id="eventId"
                                    name="eventId"
                                    value={values?.eventId}
                                    onChange={handleChange}
                                    invalid={errors.eventId && touched.eventId}
                                />
                                <ErrorMessage
                                    name="ceremoniaId"
                                    component="div"
                                    className="text-warning"
                                />
                            </FormGroup>
                            {values.hasWeddingCover == true && 
                                <ImageForm label={"Imagen de fondo de Portada de Boda"} field={"weddingCoverBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />    
                            }
                            {values.hasCover == true && 
                                <ImageForm label={"Imagen de fondo de Portada"} field={"coverBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />    
                            }
                            {values.hasInvites == true && 
                                <ImageForm label={"Imagen de fondo de Invitacion"} field={"inviteBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} preset={true}  elementClass="col-md-12" edit={edit} />    
                            }
                            {values.hasInstagram == true && 
                                <ImageForm label={"Imagen de fondo de Instagram"} field={"instaBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} preset={true}  elementClass="col-md-12" edit={edit} />    
                            }
                            {values.hasParents == true && 
                                <ImageForm label={"Imagen de fondo de Padres"} field={"parentBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} preset={true}  elementClass="col-md-12" edit={edit} />    
                            }
                            {values.hasCeremony == true && 
                                <>
                                    <ImageForm label={"Imagen de Ceremonia"} field={"ceremony"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Recepcion de Ceremonia"} field={"ceremonyReception"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de fondo de Ceremonia"} field={"ceremonyBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} preset={true}  elementClass="col-md-12" edit={edit} />
                                </>    
                            }
                            {values.hasConfirmation == true && 
                                <ImageForm label={"Imagen de fondo de Confirmacion"} field={"confirmationBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" preset={true} edit={edit} />    
                            }
                            {values.hasCounters == true && 
                                <>
                                    <ImageForm label={"Imagen izquierda de Contador"} field={"counterLeft"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen derecha de Contador"} field={"counterRight"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de fondo de Contador"} field={"counterBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} preset={true}  elementClass="col-md-12" edit={edit} />
                                </>    
                            }
                            {values.hasHostings == true && 
                                <ImageForm label={"Imagen de fondo de Hospedaje"} field={"hostingBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} preset={true}  elementClass="col-md-12" edit={edit} />    
                            }
                            {values.hasWeddingProgram == true && 
                                <>
                                    <ImageForm label={"Imagen de Cena de Programa de Boda"} field={"weddingProgramDinner"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Coctel de Programa de Boda"} field={"weddingProgramCoctail"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Recepcion de Programa de Boda"} field={"weddingProgramReception"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Ceremonia de Programa de Boda"} field={"weddingProgramCeremony"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Baile de Programa de Boda"} field={"weddingProgramDance"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Civil de Programa de Boda"} field={"weddingProgramCivil"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de fondo de Programa de Boda"} field={"weddingProgramBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" preset={true} edit={edit} />
                                </>     
                            }
                            {values.hasProgram == true && 
                                <>
                                    <ImageForm label={"Imagen de Cena de Programa"} field={"programDinner"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Coctel de Programa"} field={"programCoctail"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Recepcion de Programa"} field={"programReception"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Ceremonia de Programa"} field={"programCeremony"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Baile de XV de Programa"} field={"programXV"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de Baile de Programa"} field={"programDance"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />
                                    <ImageForm label={"Imagen de fondo de Programa"} field={"programBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" preset={true} edit={edit} />
                                </>     
                            }
                            {values.hasGifts == true && 
                                <>
                                    <ImageForm label={"Imagen de fondo de Regalos"} field={"giftsBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" preset={true} edit={edit} />    
                                    <ImageForm label={"Imagen de Link 1"} field={"giftsLink1"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />    
                                    <ImageForm label={"Imagen de Link 2"} field={"giftsLink2"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />    
                                    <ImageForm label={"Imagen de Link 3"} field={"giftsLink3"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" edit={edit} />    
                                </>
                            }
                            {values.hasDressingCode == true && 
                                <ImageForm label={"Imagen de fondo de Codigo de Vestimenta"} field={"dressingCodeBackground"} element={values} handleChange={handleChange} errors={errors} touched={touched} setFieldValue={setFieldValue} elementClass="col-md-12" preset={true} edit={edit} />    
                            }
                            <CarouselComponent values={values} setValues={setFieldValue} />
                        </ModalBody>
                        <ModalFooter>
                            <Button type="submit" color="primary">
                                Guardar Cambios
                            </Button>
                            <Button color="secondary" onClick={toggleModalImages}>
                                Cancelar
                            </Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
