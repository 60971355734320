export const baseChartOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          const dataPoint = context.raw;
          return [
            `Total: $${dataPoint.y.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            `Total Montos: $${dataPoint.totalMontos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            `Cantidad Eventos: ${dataPoint.cantidadEventos}`
          ];
        },
      },
    },
    // ... otras opciones comunes
  },
}; 