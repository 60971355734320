'use client'

import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import FormattedText from '../functions/FormattedText';

export const VestimentaBodaComponent2 = ({ datosSeccionVestimenta, colores, isEnglish }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out'
    })
  }, [])

  const fontFamilyStyle = {
    fontFamily: datosSeccionVestimenta.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif",
  };

  const textStyle = {
    ...fontFamilyStyle,
    color: datosSeccionVestimenta.textColor || '#000000'
  };

  const backgroundStyle = {
    backgroundImage: `url(${datosSeccionVestimenta.imgFondo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  // Parse colores from string if it exists
  const paletaColores = datosSeccionVestimenta.colores ? JSON.parse(datosSeccionVestimenta.colores) : null;

  return (
    <div className="min-h-auto bg-[#E5DDD3] px-0 py-24" style={{...backgroundStyle, backgroundColor: colores?.color1 || '#E5DDD3'}}>
      <div className="w-full">
        {/* Top curved section with title */}
        <div 
          data-aos="fade-down"
          className="relative min-w-full"
        >
          <div className="bg-[#C2B6AA] pb-16 pt-12 rounded-b-[50%]" style={{backgroundColor: colores?.color2 || '#C2B6AA'}}>
            <h1 className="text-center font-serif text-3xl text-white px-4" style={textStyle}>
              {isEnglish ? "Dress" : "Código de"}
              <br />
              {isEnglish ? "Code" : "Vestimenta"}
            </h1>
          </div>
        </div>

        {/* Content section */}
        <div 
          data-aos="fade-up"
          data-aos-delay="200"
          className="relative mx-4 mt-8 rounded-3xl bg-white p-8 shadow-lg"
        >
          <h2 className="text-center font-serif text-2xl text-[#8B8178] mb-8" style={textStyle}>
            {datosSeccionVestimenta.vestimenta || (isEnglish ? "Formal" : "Formal")}
          </h2>

            {datosSeccionVestimenta.messageVestimenta &&(
              <p className="text-center font-serif text-2xl text-[#8B8178] mb-8" style={textStyle}>
                <FormattedText text={datosSeccionVestimenta.messageVestimenta} />
              </p>
            )}
          <div className="grid grid-cols-2 gap-8">
            {/* Men's section */}
            <div 
              data-aos="fade-right"
              data-aos-delay="400"
              className="text-center"
            >
              <div className="mx-auto h-24 w-24 rounded-full bg-[#C2B6AA] flex items-center justify-center mb-4 hover:scale-110 transition-transform duration-300" style={{backgroundColor: colores?.color1 || '#C2B6AA'}}>
                {datosSeccionVestimenta.urlVestimentaHombre ? (
                  <img 
                    src={datosSeccionVestimenta.urlVestimentaHombre} 
                    alt={isEnglish ? "Men's Attire" : "Vestimenta Hombre"}
                    className="w-12 h-12 object-cover"
                  />
                ) : (
                  <svg className="w-12 h-12 text-white" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 3L1 9L5 11V19L12 23L19 19V11L23 9L12 3M12 5.5L19.2 9.5L12 13.5L4.8 9.5L12 5.5M17 17.2L12 20.2L7 17.2V11.7L12 14.7L17 11.7V17.2Z"/>
                  </svg>
                )}
              </div>
              <h3 className="font-serif text-xl text-[#8B8178] mb-2" style={textStyle}>{isEnglish ? "Men" : "Hombres"}</h3>
              <p className="text-sm text-[#8B8178]" style={textStyle}>
                <FormattedText text={datosSeccionVestimenta.textoHombre || (isEnglish ? "Full Suit" : "Traje Completo")} />
              </p>
            </div>

            {/* Women's section */}
            <div 
              data-aos="fade-left"
              data-aos-delay="400"
              className="text-center"
            >
              <div className="mx-auto h-24 w-24 rounded-full bg-[#C2B6AA] flex items-center justify-center mb-4 hover:scale-110 transition-transform duration-300" style={{backgroundColor: colores?.color1 || '#C2B6AA'}}>
                {datosSeccionVestimenta.urlVestimentaMujer ? (
                  <img 
                    src={datosSeccionVestimenta.urlVestimentaMujer} 
                    alt={isEnglish ? "Women's Attire" : "Vestimenta Mujer"}
                    className="w-12 h-12 object-cover"
                  />
                ) : (
                  <svg className="w-12 h-12 text-white" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12,18C12,18 11,16 11,14C11,12 12,10 12,10C12,10 13,12 13,14C13,16 12,18 12,18M12,8C10.9,8 10,7.1 10,6C10,4.9 10.9,4 12,4C13.1,4 14,4.9 14,6C14,7.1 13.1,8 12,8M12,2C9.8,2 8,3.8 8,6C8,7.1 8.4,8.1 9,8.8C9,8.8 8,10.7 8,14C8,16.5 9,19 9,19H15C15,19 16,16.5 16,14C16,10.7 15,8.8 15,8.8C15.6,8.1 16,7.1 16,6C16,3.8 14.2,2 12,2Z"/>
                  </svg>
                )}
              </div>
              <h3 className="font-serif text-xl text-[#8B8178] mb-2" style={textStyle}>{isEnglish ? "Women" : "Mujer"}</h3>
              <p className="text-sm text-[#8B8178]" style={textStyle}>
                <FormattedText text={datosSeccionVestimenta.textoMujer || (isEnglish ? "Full Dress" : "Vestido Completo")} />
              </p>
            </div>
          </div>
        </div>

        {/* Color palette section */}
        {paletaColores && (
          <div 
            data-aos="fade-up"
            data-aos-delay="600"
            className="relative mx-4 mt-8 rounded-3xl bg-white p-8 shadow-lg"
          >
            <h2 className="text-center font-serif text-2xl text-[#8B8178] mb-8" style={textStyle}>
              {isEnglish ? "Color Palette" : "Paleta de colores"}
            </h2>
            <div className="flex justify-center gap-1">
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color1 }}></div>
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color2 }}></div>
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color3 }}></div>
              <div className="h-12 w-12 rounded-full hover:scale-125 transition-transform duration-300" style={{ backgroundColor: paletaColores.color4 }}></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
