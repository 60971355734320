import React from "react";
import { FaChampagneGlasses } from "react-icons/fa6";
import { PiChurchLight } from "react-icons/pi";

export const CeremoniaXvComponent = ( { peltaColores, datosSeccionCeremonia }) => {

  const fontFamilyStyle = { fontFamily: datosSeccionCeremonia.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" };
  console.log(datosSeccionCeremonia)

  const formatTime = (isoDateString) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
    if (!isoDatePattern.test(isoDateString)) {
      return isoDateString;
    }
  
    const timePart = isoDateString.split('T')[1];
    const [hours, minutes] = timePart.split(':');
    
    const hoursNumber = parseInt(hours, 10);
    const period = hoursNumber >= 12 ? 'p.m.' : 'a.m.';
    const formattedHours = hoursNumber % 12 || 12; // Convertir 0 a 12 para las horas en formato de 12 horas
  
    return `${formattedHours}:${minutes} ${period}`;
  };
  
  return (
    <div
      style={{
        backgroundImage: `url(${datosSeccionCeremonia.imgFondo || ""})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores.color2,
        color: datosSeccionCeremonia.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col items-center w-full px-2 py-5"
    >
      {/* =========================================================================================================================================== */}
      <div className="flex flex-col sm:pt-[5vh] md:pt-[15vh] w-full justify-center items-center p-2">
        <div
          className={`grid sm:grid-cols-1 md:grid-cols-${
            datosSeccionCeremonia.dobleEnable ? "1" : "2"
          } w-[80%] pt-[10vh]`}
        >
          {datosSeccionCeremonia.ceremoniaEnable ? (
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              {
                datosSeccionCeremonia.ceremoniaImg ? (
                  <img src={datosSeccionCeremonia.ceremoniaImg} className="w-[100px]" alt="" />
                ) : <PiChurchLight className="text-[80px]" />
              }
             
              <br />
              <p className="font-serif text-2xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaTitulo || (datosSeccionCeremonia.ingles ? "Ceremony" : "Ceremonia")}
              </p>
              <br />
              <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaFecha
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {formatTime(datosSeccionCeremonia.ceremoniaHora)}
              </p>
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaNombreLugar
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaLugarMap
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <br />
              {
                datosSeccionCeremonia.ceremoniaUrl ? (
                  <a
                    href={datosSeccionCeremonia.ceremoniaUrl}
                    className="overflow-hidden text-center relative w-32 p-2 h-auto bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer z-10 group animate-bounce"
                  >
                    {datosSeccionCeremonia.ingles?"View map":"Ver mapa!"}
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                ):""
              }
             
            </div>
          ) : (
            ""
          )}

          {datosSeccionCeremonia.dobleEnable ? (
            <div
              className="flex flex-col justify-center items-center"
              data-aos="fade-right"
            >
              <div className="flex">
                {
                  datosSeccionCeremonia.ceremoniaImg ? (
                    <img src={datosSeccionCeremonia.ceremoniaImg} className="w-[100px]" alt="" />
                  ) : <PiChurchLight className="text-[80px]" />
                }
                {
                  datosSeccionCeremonia.recepcionImg ? (
                    <img src={datosSeccionCeremonia.recepcionImg} className="w-[100px]" alt="" />
                  ) : <FaChampagneGlasses className="text-[80px]" />
                }
              </div>
              <br />
              <p className="font-serif text-center text-2xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaTitulo ||(datosSeccionCeremonia.ingles?"Ceremony": "Ceremonia")} {datosSeccionCeremonia.ingles ? "and" : "y"} {datosSeccionCeremonia.recepcionTitulo || (datosSeccionCeremonia.ingles?"Reception": "Recepción")}
              </p>
              <br />
              <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
              <br />
              <p className="font-serif text-center text-xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaFecha
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {formatTime(datosSeccionCeremonia.ceremoniaHora)}
              </p>
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaNombreLugar
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]" style={fontFamilyStyle}>
                {datosSeccionCeremonia.ceremoniaLugarMap
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <br />
              {
                datosSeccionCeremonia.ceremoniaUrl ? (
                  <a
                    href={datosSeccionCeremonia.ceremoniaUrl}
                    className="text-center overflow-hidden relative w-32 p-2 h-auto bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
                  >
                     {datosSeccionCeremonia.ingles?"View map":"Ver mapa!"}
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                ):""
              }
            </div>
          ) : (
            ""
          )}

          {datosSeccionCeremonia.recepcionEnable ? (
            <div
              className="flex flex-col justify-center items-center sm:pt-[5vh] md:pt-0"
              data-aos="fade-right"
            >
              {
                datosSeccionCeremonia.recepcionImg ? (
                  <img src={datosSeccionCeremonia.recepcionImg} className="w-[100px]" alt="" />
                ) : <FaChampagneGlasses className="text-[80px]" />
              }
              <br />
              <p className="font-serif text-2xl" style={fontFamilyStyle}>
                {datosSeccionCeremonia.recepcionTitulo ||(datosSeccionCeremonia.ingles?"Reception": "Recepción")}
              </p>
              <br />
              <hr className="bg-[#C6C6C6] h-2 w-[80%]" />
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionCeremonia.recepcionFecha
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {formatTime(datosSeccionCeremonia.recepcionHora)}
              </p>
              <br />
              <p className="font-serif text-xl text-center" style={fontFamilyStyle}>
                {datosSeccionCeremonia.recepcionNombreLugar
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <br />
              <p className="font-serif text-xl text-center sm:w-[70%] md:w-[80%]" style={fontFamilyStyle}>
                {datosSeccionCeremonia.recepcionLugarMap
                  .split("\\n")
                  .map((linea, index) => (
                    <React.Fragment key={index}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
              <br />
              {
                datosSeccionCeremonia.recepcionUrl ? (
                  <a
                    href={datosSeccionCeremonia.recepcionUrl}
                    className="text-center overflow-hidden relative w-32 p-2 h-auto bg-black text-white border-none rounded-md text-xl font-bold cursor-pointer animate-bounce  z-10 group"
                  >
                     {datosSeccionCeremonia.ingles?"View map":"Ver mapa!"}
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-white rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-500 duration-1000 origin-left"></span>
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-200 rotate-12 transform scale-x-0 group-hover:scale-x-100 transition-transform group-hover:duration-700 duration-700 origin-left"></span>
                    <span className="absolute w-36 h-32 -top-8 -left-2 bg-amber-300 rotate-12 transform scale-x-0 group-hover:scale-x-50 transition-transform group-hover:duration-1000 duration-500 origin-left"></span>
                    <span className="group-hover:opacity-100 group-hover:duration-1000 duration-100 opacity-0 absolute top-2.5 left-6 z-10">
                      Explore!
                    </span>
                  </a>
                ):""
              }
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
