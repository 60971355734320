import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Input, Label } from "reactstrap";
import { useLocation } from "react-router-dom";
import { Vestimenta } from "../../../../components/Vestimenta";
import axios from "axios";
import toast from "react-hot-toast";
import { ImageFormComponent } from ".././Images/ImageFormComponent";
import { CarouselComponent } from ".././Images/CarouselComponent";
import { ImageFondo } from ".././Images/ImageFondo";
import ModalFormularioPre from ".././ModalFormularioPre";
import { LoadingComponent } from "../../../../components/LoadingComponent";
import SelectTipoLetra from "../../../../components/functions/SelectTipoLetra";
import { SelectIcons } from "../../../../components/functions/SelectIcons";
import { SelectSobre } from "../../../../components/functions/SelectSobre";
import Swal from "sweetalert2";
import { BasicInfoForm } from './Components/BasicInfoForm';
import { BackgroundColorsForm } from './Components/BackgroundColorsForm';
import { ContactAndFamilyInfoForm } from './Components/ContactAndFamilyInfoForm';
import { CeremonyAndReceptionForm } from './Components/CeremonyAndReceptionForm';
import { HospedajeForm } from './Components/HospedajeForm';
import { GiftRegistryForm } from './Components/GiftRegistryForm';
import { ProgramForm } from "./Components/ProgramForm";
import { VestimentaForm } from './Components/VestimentaForm';
import { InstagramPostsForm } from './Components/InstagramPostsForm';
import { checkSpelling } from '../../../../components/functions/checkSpelling';

// Define form sections for the stepper


export const FormularioAuto = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idEvento = searchParams.get("id");
  const [dataEvento, setDataEvento] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isAlreadySet, setAlreadySet] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Obtener los datos actuales del localStorage o crear un nuevo objeto si no existen
    const formData = JSON.parse(localStorage.getItem("formData")) || {};

    // Actualizar el campo correspondiente en el objeto
    formData[name] = value;

    // Guardar el objeto actualizado en el localStorage
    localStorage.setItem("formData", JSON.stringify(formData));
  };

  const formSections = [
    { id: 'basic-info', title: 'Información Básica', component: BasicInfoForm },
    { id: 'background', title: 'Colores de Fondo', component: BackgroundColorsForm },
    { id: 'contact-family', title: 'Contacto y Familia', component: ContactAndFamilyInfoForm, enabled: dataEvento?.enablePadres },
    { id: 'ceremony', title: 'Ceremonia y Recepción', component: CeremonyAndReceptionForm, enabled: dataEvento?.enableCeremonia },
    { id: 'hospedaje', title: 'Hospedaje', component: HospedajeForm, enabled: dataEvento?.enableHospedaje },
    { id: 'gifts', title: 'Mesa de Regalos', component: GiftRegistryForm, enabled: dataEvento?.enableRegalos },
    { id: 'program', title: 'Programa', component: ProgramForm, enabled: dataEvento?.enablePrograma },
    { id: 'vestimenta', title: 'Vestimenta', component: VestimentaForm, enabled: dataEvento?.enableVestimenta },
    { id: 'instagram', title: 'Instagram', component: InstagramPostsForm, enabled: dataEvento?.enableInsta },
    { id: 'final', title: 'Detalles Finales', component: null }
  ];

  // Filter sections based on their enabled status
  const enabledSections = formSections.filter(section => section.enabled !== false);


  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/Eventos/${idEvento}`);
      let data = response.data;
      setDataEvento({ ...data });
      if (data.fechaFin != "Nada") {
        setAlreadySet(true);
      }
      console.log(response.data);
      console.log(dataEvento);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Algo salio mal, intenta de nuevo");
    }
  };

  const capitalizeWords = (str) => {
    const exceptions = new Set(["del", "de", "la", "y"]);
    return str
      .toLocaleLowerCase("es")
      .replace(/(?:^|\s)\p{L}/gu, (match) => match.toLocaleUpperCase("es"))
      .split(" ")
      .map((word) =>
        exceptions.has(word.toLowerCase()) ? word.toLowerCase() : word
      )
      .join(" ");
  };

  // Obtener formData de localStorage si existe
  const savedFormData = JSON.parse(localStorage.getItem("formData")) || {};

  const initialValues = {
    cancion: savedFormData.cancion || "",
    color1: savedFormData.color1 || "#e7e3e3",
    color2: savedFormData.color2 || "#f8f2f2",
    D1esposo: savedFormData.D1esposo || "",
    D1esposa: savedFormData.D1esposa || "",
    D1fechaDeBoda: savedFormData.D1fechaDeBoda || "",
    D1numeroNovia: savedFormData.D1numeroNovia || "",
    D1numeroNovio: savedFormData.D1numeroNovio || "",
    D1numeroWiddingAdmin: savedFormData.D1numeroWiddingAdmin || "",
    D1compradorInv: savedFormData.D1compradorInv || "",
    D2descipcion: savedFormData.D2descipcion ||
      "Después de 4 años caminando juntos hemos decidido unir nuestras vidas en matrimonio, por lo que nos complace que sean participes de esta unión...",
    D3esposaMadre: savedFormData.D3esposaMadre || "",
    D3esposaPadre: savedFormData.D3esposaPadre || "",
    D3esposoMadre: savedFormData.D3esposoMadre || "",
    D3esposoPadre: savedFormData.D3esposoPadre || "",
    madrina: savedFormData.madrina || "",
    padrino: savedFormData.padrino || "",
    D4ceremoniaFecha: savedFormData.D4ceremoniaFecha || "",
    D4ceremoniaNombreLugar: savedFormData.D4ceremoniaNombreLugar || "",
    D4ceremoniaUrl: savedFormData.D4ceremoniaUrl || "",
    D4recepcionFecha: savedFormData.D4recepcionFecha || "",
    D4recepcionNombreLugar: savedFormData.D4recepcionNombreLugar || "",
    D4recepcionUrl: savedFormData.D4recepcionUrl || "",
    D7hotel1lugar: savedFormData.D7hotel1lugar || "",
    D7hotel1url: savedFormData.D7hotel1url || "",
    D7hotel2lugar: savedFormData.D7hotel2lugar || "",
    D7hotel2url: savedFormData.D7hotel2url || "",
    D8linkRegalo1: savedFormData.D8linkRegalo1 || "",
    D8linkRegalo2: savedFormData.D8linkRegalo2 || "",
    D8linkRegalo3: savedFormData.D8linkRegalo3 || "",
    D8linkRegalo4: savedFormData.D8linkRegalo4 || "",
    Vestimenta: savedFormData.Vestimenta || "",
    VestimentaAviso: savedFormData.VestimentaAviso || "",
    coloresVestimenta: savedFormData.coloresVestimenta || JSON.stringify({
      color1: "#FFFFFF",
      color2: "#4A3728",
      color3: "#D2B48C",
      color4: "#1A1A1A"
    }),
    Detalles: savedFormData.Detalles || "",
    PceremoniaReligiosaIcon: savedFormData.PceremoniaReligiosaIcon || "",
    PceremoniaReligiosa: savedFormData.PceremoniaReligiosa || "Acompáñanos a recibir la bendición de Dios",
    PceremoniaReligiosaTitulo: savedFormData.PceremoniaReligiosaTitulo || "CEREMONIA RELIGIOSA",
    PrecepcionIcon: savedFormData.PrecepcionIcon || "",
    Precepcion: savedFormData.Precepcion || "Tómate fotos con nosotros",
    PrecepcionTitulo: savedFormData.PrecepcionTitulo || "RECEPCIÓN",
    PcivilIcon: savedFormData.PcivilIcon || "",
    Pcivil: savedFormData.Pcivil || "No te pierdas nuestro intercambio de votos",
    PcivilTitulo: savedFormData.PcivilTitulo || "CEREMONIA CIVIL",
    PcenaIcon: savedFormData.PcenaIcon || "",
    Pcena: savedFormData.Pcena || "Disfruta de una deliciosa comida",
    PcenaTitulo: savedFormData.PcenaTitulo || "COMIDA",
    PcoctelIcon: savedFormData.PcoctelIcon || "",
    Pcoctel: savedFormData.Pcoctel || "Disfruta de un refrescante cóctel",
    PcoctelTitulo: savedFormData.PcoctelTitulo || "COCTEL",
    PbaileIcon: savedFormData.PbaileIcon || "",
    Pbaile: savedFormData.Pbaile || "Sacale brillo a la pista",
    PbaileTitulo: savedFormData.PbaileTitulo || "BAILE",
    imgFondo: savedFormData.imgFondo || "",
    tipoLetra: savedFormData.tipoLetra || "",
    mail: savedFormData.mail || "",
    sobre: savedFormData.sobre || 0,
    instagramTitulo: savedFormData.instagramTitulo || '',
    instagramHashtag: savedFormData.instagramHashtag || '',
    instagramDescripcion: savedFormData.instagramDescripcion || '',
    instagramTextColor: savedFormData.instagramTextColor || '',
  };

  const onSubmit = async (values) => {
    const confirmed = window.confirm("Solo puedes enviar este formulario una vez. ¿Deseas continuar?");

    if (!confirmed) {
      console.log("Envío del formulario cancelado");
      return;
    }
    toast.loading(`Enviando Formulario...`);
    setLoadingSend(true);
    console.log("Datos del formulario:", values, idEvento);
    // Puedes agregar lógica para enviar los datos a través de un servicio, como emailjs.
    const portadaBoda = {
      textColor: values.textColor,
      cancion: values.cancion,
      esposo: capitalizeWords(values.D1esposo),
      esposa: capitalizeWords(values.D1esposa),
      fechaDeBoda: values.D1fechaDeBoda,
      imgPortada: "",
      titulo: "NO FALTES A NUESTRA BODA",
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      sobre: values.sobre,
      posicion: ""
    };

    const contador = {
      titulo: "¡Nos Casamos!",
      descripcion: values.D2descipcion,
      fechaNewDate:
        values.D1fechaDeBoda + " = " + "December 12, 2024 00:00:00 GMT+00:00",
      fotoIzquierda: "",
      fotoDerecha: "",
      marcoEnable: true,
      textColor: null,
      imgFondo: "",
      contador: true,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    let cantidad;

    if (values.D3esposaMadre || values.D3esposaPadre) {
      cantidad = 1;

      if (values.D3esposoMadre || values.D3esposoPadre) {
        cantidad = 2;

        if (values.madrina || values.padrino) {
          cantidad = 3;
        } else {
          cantidad = 2; // O cualquier valor predeterminado si no se cumple ninguno de los casos
        }
      } else {
        cantidad = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
      }
    } else {
      cantidad = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
    }

    const padresBoda = {
      cantidad: cantidad,
      titulo: "¡Celebra con nosotros este día tan maravilloso!",
      descripcion: null,
      titulo1: "Padres de la Novia",
      madre: capitalizeWords(values.D3esposaMadre),
      padre: capitalizeWords(values.D3esposaPadre),
      titulo2: "Padres del Novio",
      madrina: capitalizeWords(values.D3esposoMadre),
      padrino: capitalizeWords(values.D3esposoPadre),
      titulo3: "Padrinos",
      madrina2: capitalizeWords(values.madrina),
      padrino2: capitalizeWords(values.padrino),
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const ceremoniaBoda = {
      dobleEnable: false,
      ceremoniaImg: "",
      ceremoniaTitulo: "",
      ceremoniaEnable: values.D4ceremoniaFecha ? true : false,
      ceremoniaFecha: "Sábado, 25 de Mayo de 2024.",
      ceremoniaHora: values.D4ceremoniaFecha,
      ceremoniaNombreLugar: capitalizeWords(values.D4ceremoniaNombreLugar),
      ceremoniaLugarMap: "",
      ceremoniaUrl: values.D4ceremoniaUrl,
      recepcionEnable: values.D4recepcionFecha ? true : false,
      recepcionImg: "",
      recepcionTitulo: "",
      recepcionFecha: "Sábado, 25 de Mayo de 2024.",
      recepcionHora: values.D4recepcionFecha,
      recepcionNombreLugar: capitalizeWords(values.D4recepcionNombreLugar),
      recepcionLugarMap: "",
      recepcionUrl: values.D4recepcionUrl,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const vestimenta = {
      vestimenta: values.Vestimenta,
      messageVestimenta: values.VestimentaAviso,
      urlVestimentaMujer: null,
      urlVestimentaHombre: null,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      textHombre: "",
      textMujer: "",
      colores: values.coloresVestimenta,
    };

    const confirmacion = {
      tituloConfirmacion:
        "Espero puedan confirmarnos su asistencia. \\n Agradecemos de antemano su apreciable compañía.",
      aviso: values.Detalles,
      numeroConfirmacion: `+52${values.D1numeroNovia} ${" "}${values.D1numeroWiddingAdmin
        }`,
      textoConfirmacion: "a la Boda",
      textWhatsApp: null,
      textColor: null,
      imgFondo: values.imgFondo,
      numeroNovia: `+52${values.D1numeroNovia}`,
      numeroNovio: `+52${values.D1numeroNovio}`,
      enableNum: false,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
      confetti: false,
    };

    const programaBoda = {
      ceremoniaReligiosa: values.PceremoniaReligiosa,
      ceremoniaReligiosaImg: values.PceremoniaReligiosaIcon,
      ceremoniaReligiosaTitulo: values.PceremoniaReligiosaTitulo,
      recepcion: values.Precepcion,
      recepcionImg: values.PrecepcionIcon,
      recepcionTitulo: values.PrecepcionTitulo,
      civil: values.Pcivil,
      civilImg: values.PcivilIcon,
      civilTitulo: values.PcivilTitulo,
      cena: values.Pcena,
      cenaImg: values.PcenaIcon,
      cenaTitulo: values.PcenaTitulo,
      coctel: values.Pcoctel,
      coctelImg: values.PcoctelIcon,
      coctelTitulo: values.PcoctelTitulo,
      baile: values.Pbaile,
      baileImg: values.PbaileIcon,
      baileTitulo: values.PbaileTitulo,
      titulo: "",
      descripcion: "",
      idEvento: idEvento,
      textColor: "",
      imgFondo: values.imgFondo,
      tipoLetra: values.tipoLetra,
    };


    const instagram = {
      titulo: values.instagramTitulo,
      hashtag: values.instagramHashtag,
      descripcion: values.instagramDescripcion,
      textColor: values.instagramTextColor,
      imgFondo: "",
      tipoLetra: values.tipoLetra,
      idEvento: idEvento
    };

    const invitados = {
      imgFondo: values.imgFondo,
      titulo: "",
      entradasText: "la Boda.",
      colorFondoName: "",
      eventoTitulo: "",
      limiteAccesos: 150,
      IdEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    let cantidadRegalos;

    if (values.link1) {
      cantidadRegalos = 1;

      if (values.D8linkRegalo2) {
        cantidadRegalos = 2;

        if (values.D8linkRegalo3) {
          cantidadRegalos = 3;
        } else {
          cantidadRegalos = 2; // O cualquier valor predeterminado si no se cumple ninguno de los casos
        }
      } else {
        cantidadRegalos = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
      }
    } else {
      cantidadRegalos = 1; // O cualquier valor predeterminado si no se cumple ninguno de los casos
    }

    const regalos = {
      cantidadRegalos: (values.D8linkRegalo1 ? 1 : 0) + (values.D8linkRegalo2 ? 1 : 0) + (values.D8linkRegalo3 ? 1 : 0) || 1,
      titulo: "Mesa de Regalos",
      descripcion:
        "Su presencia en mi fiesta de quince años es el mejor regalo que puedo recibir. Sin embargo, si desean hacerme un obsequio adicional, les agradezco de antemano por su generosidad. ¡Gracias por ser parte de este día tan especial!",
      link1: values.D8linkRegalo1,
      imgLink1: values.D8linkRegalo1
        ? (values.D8linkRegalo1.includes('liverpool.com')
          ? "https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg"
          : values.D8linkRegalo1.includes('amazon.com')
            ? "https://i.postimg.cc/xTDxmzMZ/IMG_5645.png"
            : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png")
        : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
      link1Enable: values.D8linkRegalo1 ? !/^[a-zA-Z0-9\s]+$/.test(values.D8linkRegalo1) : false,
      link2: values.D8linkRegalo2,
      imgLink2: values.D8linkRegalo2
        ? (values.D8linkRegalo2.includes('liverpool.com')
          ? "https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg"
          : values.D8linkRegalo2.includes('amazon.com')
            ? "https://i.postimg.cc/xTDxmzMZ/IMG_5645.png"
            : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png")
        : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
      link2Enable: values.D8linkRegalo2 ? !/^[a-zA-Z0-9\s]+$/.test(values.D8linkRegalo2) : false,
      link3: values.D8linkRegalo3,
      imgLink3: values.D8linkRegalo3
        ? (values.D8linkRegalo3.includes('liverpool.com')
          ? "https://assetspwa.liverpool.com.mx/assets/images/logos/liverpool-logo.svg"
          : values.D8linkRegalo3.includes('amazon.com')
            ? "https://i.postimg.cc/xTDxmzMZ/IMG_5645.png"
            : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png")
        : "https://i.postimg.cc/BQx3Vgkp/B933-DA19-48-D7-40-F9-B4-CA-5-B82-B72447-A9.png",
      link3Enable: values.D8linkRegalo3 ? !/^[a-zA-Z0-9\s]+$/.test(values.D8linkRegalo3) : false,
      link4: "",
      imgLink4: "",
      link4Enable: false,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra
    };

    const hospedaje = {
      disponibleDos: true,
      descripcion:
        "Para una estadía más placentera, te compartimos estas sugerencias de hospedaje.",
      hotel1Titulo: "...",
      hotel1lugar: values.D7hotel1lugar,
      hotel1url: values.D7hotel1url,
      hotel2Titulo: "...",
      hotel2lugar: values.D7hotel2lugar,
      hotel2url: values.D7hotel2url,
      imgFondo: values.imgFondo,
      textColor: null,
      idEvento: idEvento,
      tipoLetra: values.tipoLetra,
    };

    const colores = {
      color1: values.color1,
      color2: values.color2,
      idEvento: idEvento,
    };

    let requiredImageKeys = [
      "eventId",
      "weddingCoverBackgroundImg",
      "weddingCoverBackgroundFile",
      "counterLeftImg",
      "counterLeftFile",
      "counterRightImg",
      "counterRightFile",
      "carousel",
      "instaBackgroundImg",
      "instaBackgroundFile",
      "parentsBackgroundImg",
      "parentsBackgroundFile",
      "inviteBackgroundImg",
      "inviteBackgroundFile",
      "counterBackgroundImg",
      "counterBackgroundFile",
    ];

    let data = new FormData();
    requiredImageKeys.forEach((value) => {
      switch (value) {
        case "carousel":
          let items = values[value];
          if (typeof items == "undefined") {
            break;
          }

          items.forEach((x, index) => {
            data.append(`${value}[${index}].Id`, x.id);
            data.append(`${value}[${index}].Url`, x.url);
            data.append(`${value}[${index}].Replacement`, x.replacement);
            data.append(`${value}[${index}].Deleted`, x.deleted);
          });
          break;
        case "eventId":
          console.log(idEvento);
          data.append(value, idEvento);
          break;
        default:
          data.append(value, values[value]);
          break;
      }
    });

    try {
      // Enviar todas las secciones en una sola petición
      const response = await axios.post("/api/Formularios/secciones-boda", {
        portadaBoda,
        colores,
        contador,
        padresBoda,
        ceremoniaBoda,
        vestimenta,
        confirmacion,
        programaBoda,
        regalos,
        hospedaje,
        invitados,
        instagram
      });

      if (response.status === 200) {
        // Actualizar el evento
        if(dataEvento.fechaFin != ("Test" || "Ejemplo")){
          dataEvento.fechaFin = "Formulario";
        }
        dataEvento.mail = values.mail;
        const eventData = { ...dataEvento, enablePortada: true };
        await axios.put(`/api/Eventos/${idEvento}`, eventData);

        // Procesar imágenes
        const updateImages = await axios.post(`/api/Images`, data);
        console.log("Respuesta del servidor:", updateImages.data);

        console.log("Datos enviados correctamente");
        const fechaActual = new Date();

        // Sumar 5 días para la fecha predeterminada
        const fechaFinal = sumarDias(new Date(fechaActual), 5);

        // Sumar 2 días para la fecha express
        const fechaFinalEx = sumarDias(new Date(fechaActual), 2);

        // Formatear las fechas en el formato mexicano (es-ES) con el huso horario de México
        const opcionesFecha = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/Mexico_City' };
        const fechaFormateada = fechaFinal.toLocaleDateString('es-ES', opcionesFecha);
        const fechaFormateadaEx = fechaFinalEx.toLocaleDateString('es-ES', opcionesFecha);

        // Mensaje en la consola
        console.log("Datos enviados correctamente");

        // Mostrar la alerta con la fecha de finalización
        alert(`¡Tu formulario ha sido enviado con éxito! 
Tu invitación estará lista en las siguientes fechas:
- Fecha predeterminada: ${fechaFormateada}
- Fecha express: ${fechaFormateadaEx}`);
        Swal.fire({
          title: '¡Tu formulario ha sido enviado con éxito!',
          html: `
    <p>Para dar seguimiento, por favor contacta con la persona que te atendió.</p>
    <p>Este aviso confirma que tu formulario ha sido enviado correctamente.</p>
    <p>Fechas estimadas de entrega:</p>
    <ul>
      <li>Fecha predeterminada: ${fechaFormateada}</li>
      <li>Fecha express: ${fechaFormateadaEx}</li>
    </ul>
  `,
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Enviar mensaje por WhatsApp',
          cancelButtonText: 'Cerrar',
          customClass: {
            confirmButton: 'swal-confirm-btn',
            cancelButton: 'swal-cancel-btn'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            setLoadingSend(false);
            toast.dismiss();
            const message = encodeURIComponent("Hola, buen día. Mi formulario ya ha sido enviado.");
            const whatsappUrl = `https://wa.me/4491516931?text=${message}`;
            window.open(whatsappUrl, '_blank');
          }
          toast.dismiss();
          if (window.location.hostname === "yourinvitation.vip") {
            window.location.href = "/es";
          } else {
            window.location.href = "/";
          }
          setLoadingSend(false);
        });
      }
    } catch (error) {
      toast.dismiss();
      alert("Este formulario ya ha sido contestado o intentalo mas tarde");
      console.error("Error al enviar los datos:", error);
      setLoadingSend(false);
    }
  };

  const clearFormData = () => {
    const confirmed = window.confirm("¿Quieres reiniciar el formulario y borrar los datos guardados?");


    if (!confirmed) {
      console.log("Reseteo de formulario cancelado");
      return
    }
    localStorage.removeItem("formData");
    // Opcionalmente, podrías recargar la página o resetear los valores
    window.location.reload(); // Para recargar la página y resetear el formulario
  };

  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  const [useImage, setUseImage] = useState(false);

  const handleCheckboxChange = (e) => {
    setUseImage(e.target.checked);
  };

  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    getData();
    console.log(capitalizeWords("ANGÉL jaimés de la torre"));
  }, []);

  // Function to check if a section is complete
  const isSectionComplete = (values, sectionId) => {
    switch (sectionId) {
      case 'basic-info':
        return values.D1esposo && values.D1esposa && values.D1fechaDeBoda;
      case 'background':
        return values.color1 && values.color2;
      case 'contact-family':
        return values.D1numeroNovia && values.D1numeroNovio;
      // Add more cases for other sections
      default:
        return true;
    }
  };

  const handleStepComplete = (stepId) => {
    setCompletedSteps(prev => new Set([...prev, stepId]));
  };

  const handleNextStep = () => {
    if (currentStep < enabledSections.length - 1) {
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div
      className="w-full min-h-screen bg-black md:p-10 sm:p-2"
      style={{
        backgroundImage: `url(https://digitalrsvp.mx/api/Images/1cb8085a-ac04-46ca-b7aa-b1a20dd68a30)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="max-w-7xl mx-auto">
          {/* Progress Stepper */}
          <div className="bg-white rounded-lg shadow-md p-4 mb-8">
            <div className="flex flex-wrap justify-center gap-2">
              {enabledSections.map((section, index) => (
                <div
                  key={section.id}
                  className={`flex items-center ${index !== enabledSections.length - 1 ? 'flex-1' : ''}`}
                >
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center text-sm font-bold
                      ${currentStep === index ? 'bg-blue-500 text-white' :
                        completedSteps.has(section.id) ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
                  >
                    {completedSteps.has(section.id) ? '✓' : index + 1}
                  </div>
                  {index !== enabledSections.length - 1 && (
                    <>
                      <div className="hidden md:block text-xs ml-2">{section.title}</div>
                      <div className="flex-1 h-1 mx-2 bg-gray-200">
                        <div
                          className={`h-full ${completedSteps.has(section.id) ? 'bg-green-500' :
                              currentStep === index ? 'bg-blue-500' : ''
                            }`}
                          style={{
                            width: completedSteps.has(section.id) ? '100%' :
                              currentStep === index ? '50%' : '0%'
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              handleReset,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form className="space-y-8" onSubmit={handleSubmit}>
                {/* Warning Messages */}
                {isAlreadySet && (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg mb-6">
                    <p className="font-bold">¡Atención!</p>
                    <p>Este formulario ya ha sido llenado previamente.</p>
                  </div>
                )}

                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded-lg mb-6">
                  <p className="font-bold">¡Atención!</p>
                  <p>Por favor, asegúrate de ESCRIBIR CORRECTAMENTE LOS NOMBRES Y TEXTOS, ya que así es como aparecerán en la invitación.</p>
                </div>

                {/* Clear Form Data Button */}
                <div className="flex justify-end mb-6">
                  <button
                    type="button"
                    onClick={clearFormData}
                    className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
                  >
                    Eliminar datos guardados
                  </button>
                </div>

                {/* Current Section Component */}
                <div className="bg-white shadow-lg rounded-lg p-8">
                  <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                    {enabledSections[currentStep].title}
                  </h2>

                  {/* Render current section component */}
                  {enabledSections[currentStep].component && (
                    <>
                      {(() => {
                        const CurrentComponent = enabledSections[currentStep].component;
                        return (
                          <CurrentComponent
                            values={values}
                            handleChange={handleChange}
                            handleInputChange={handleInputChange}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                          />
                        );
                      })()}
                    </>
                  )}

                  {/* Final section with additional components */}
                  {currentStep === enabledSections.length - 1 && (
                    <>
                      <div className="space-y-6">
                        {/* Song and Font Type Section */}
                        <div className="border-t pt-6">
                          <h3 className="text-xl font-semibold mb-4">Canción y Tipo de Letra</h3>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                              <Label htmlFor="cancion">URL de laCanción</Label>
                              <Input
                                id="cancion"
                                name="cancion"
                                onChange={(e) => { handleChange(e); handleInputChange(e); }}
                                value={values.cancion}
                                className="w-full"
                              />
                            </div>
                            <SelectTipoLetra values={values} handleChange={handleChange} />

                          </div>
                        </div>

                        {/* Comments Section */}
                        <div className="border-t pt-6">
                          <h3 className="text-xl font-semibold mb-4">Comentarios o Detalles</h3>
                          <textarea
                            name="Detalles"
                            rows="6"
                            onChange={(e) => { handleChange(e); handleInputChange(e); }}
                            value={values.Detalles}
                            className="w-full rounded-md border-gray-300"
                          />
                        </div>

                        {/* Carousel Component */}
                        <div className="border-t pt-6">
                          <CarouselComponent
                            values={values}
                            setValues={setFieldValue}
                            paquete={dataEvento?.paquete}
                          />
                        </div>
                      </div>
                      {/* Modal */}
                      {isOpen && (
                        <ModalFormularioPre
                          isOpen={isOpen}
                          toggleModal={toggleModal}
                          datos={values}
                          dataEvento={dataEvento}
                        />
                      )}

                      {/* Final Actions */}
                      <div className="mt-8 flex flex-col md:flex-row justify-center gap-4">
                        <button
                          type="button"
                          onClick={async () => {
                            const textsToCheck = {
                              D2descipcion: values.D2descipcion,
                              D3esposaMadre: values.D3esposaMadre,
                              D3esposaPadre: values.D3esposaPadre,
                              D3esposaMadre: values.D3esposaMadre,
                              D3esposaPadre: values.D3esposaPadre,
                              D3esposoMadre: values.D3esposoMadre,
                              D3esposoPadre: values.D3esposoPadre,
                              madrina: values.madrina,
                              padrino: values.padrino,
                              VestimentaAviso: values.VestimentaAviso,
                            };

                            for (const [field, text] of Object.entries(textsToCheck)) {
                              if (text) {
                                const result = await checkSpelling(text);
                                if (!result.cancelled) {
                                  setFieldValue(field, result.text);
                                }
                              }
                            }
                          }}
                          className="bg-purple-500 hover:bg-purple-600 text-white px-6 py-3 rounded-lg transition-colors"
                        >
                          Validar ortografía
                        </button>
                        <button
                          type="submit"
                          disabled={loadingSend}
                          className="bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-lg transition-colors"
                        >
                          Enviar Formulario
                        </button>
                      </div>
                    </>
                  )}

                  {/* Navigation Buttons */}
                  <div className="flex justify-between mt-8">
                    <button
                      type="button"
                      onClick={handlePrevStep}
                      disabled={currentStep === 0}
                      className={`px-4 py-2 rounded-lg ${currentStep === 0
                          ? 'bg-gray-300 cursor-not-allowed'
                          : 'bg-gray-500 hover:bg-gray-600 text-white'
                        }`}
                    >
                      Anterior
                    </button>
                    {currentStep < enabledSections.length - 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          if (isSectionComplete(values, enabledSections[currentStep].id)) {
                            handleStepComplete(enabledSections[currentStep].id);
                            handleNextStep();
                          }
                        }}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
                      >
                        Siguiente
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>


        </div>
      )}
    </div>
  );
};
