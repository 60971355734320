import React from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const ContadorForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Contador</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="contador" className="block text-sm font-medium text-gray-700 mb-1">
                    Contador:
                  </label>
                  <Input
                    type="checkbox"
                    id="contador"
                    name="contador"
                    checked={values?.contador}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-1">
                    Descripción:
                  </label>
                  <textarea
                    id="descripcion"
                    name="descripcion"
                    value={values?.descripcion}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="fechaNewDate" className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha:
                  </label>
                  <Input
                    type="text"
                    id="fechaNewDate"
                    name="fechaNewDate"
                    value={values?.fechaNewDate}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="fotoDerecha" className="block text-sm font-medium text-gray-700 mb-1">
                    Foto Abajo de Contador (Derecha):
                  </label>
                  <Input
                    type="text"
                    id="fotoDerecha"
                    name="fotoDerecha"
                    value={values?.fotoDerecha}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="fotoIzquierda" className="block text-sm font-medium text-gray-700 mb-1">
                    Foto Arriba de Contador (Izquierda):
                  </label>
                  <Input
                    type="text"
                    id="fotoIzquierda"
                    name="fotoIzquierda"
                    value={values?.fotoIzquierda}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="marcoEnable" className="block text-sm font-medium text-gray-700 mb-1">
                    Marco Habilitado:
                  </label>
                  <Input
                    type="checkbox"
                    id="marcoEnable"
                    name="marcoEnable"
                    checked={values?.marcoEnable}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Título:
                  </label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={values?.titulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Color de Texto:
                  </label>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor}
                    onChange={handleChange}
                    className="mt-1 block w-full h-10"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Seleccionar imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      values?.imgFondo ? "input-error" : ""
                    }`}
                  />
                </div>
                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Guardar Contador
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 