import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EventosMontoMenor = ({ startDate, endDate, selectedYear }) => {
  const [eventos, setEventos] = useState([]);
  const [filterFechaFin, setFilterFechaFin] = useState('');
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const fetchEventos = async () => {
    try {
      let url = "/api/Eventos/monto-menor-que-total";
      const params = new URLSearchParams();
      
      if (localStartDate && localEndDate) {
        params.append('startDate', localStartDate);
        params.append('endDate', localEndDate);
      }
      
      params.append('year', selectedYear);
      
      if (params.toString()) {
        url += `?${params.toString()}`;
      }
      
      const response = await axios.get(url);
      setEventos(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEventos();
  }, [localStartDate, localEndDate, selectedYear]);

  return (
    <div className="bg-white p-6 rounded-xl shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
        <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
        Eventos con Monto Menor que Total
      </h2>
      <div className="mb-6 space-y-4">
        <div className="space-y-4">
          <div className="flex flex-col gap-4">
            <div className="flex-1">
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Inicio
              </label>
              <input
                type="date"
                id="startDate"
                className="p-3 border border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                value={localStartDate}
                onChange={(e) => setLocalStartDate(e.target.value)}
              />
            </div>
            <div className="flex-1">
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                Fecha Fin
              </label>
              <input
                type="date"
                id="endDate"
                className="p-3 border border-gray-300 rounded-lg w-full focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                value={localEndDate}
                onChange={(e) => setLocalEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <button
              className="flex-1 p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => {
                if (localStartDate && localEndDate) {
                  fetchEventos();
                }
              }}
              disabled={!localStartDate || !localEndDate}
            >
              Filtrar por fechas
            </button>
            <button
              className="flex-1 p-3 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition duration-300"
              onClick={() => {
                setLocalStartDate('');
                setLocalEndDate('');
                fetchEventos();
              }}
            >
              Limpiar fechas
            </button>
          </div>
        </div>

        <label htmlFor="filterFechaFin" className="block text-lg font-semibold mb-3 text-gray-700">
          Filtrar por FechaFin:
        </label>
        <select 
          id="filterFechaFin" 
          className="p-3 border border-gray-300 rounded-lg w-full md:w-1/3 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
          onChange={(e) => setFilterFechaFin(e.target.value)}
        >
          <option value="">Mostrar todos</option>
          {['Formateado', 'Formulario', 'Nada', 'Entregado'].map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50">
              {[
                'Id', 'Nombre', 'Num Comprador', 'Tipo Evento', 'Paquete',
                'Fecha Inicio', 'Fecha Fin', 'Monto', 'Detalles', 'Total',
                'Socio', 'Enable Evento', 'Fecha Evento', 'Semanas Restantes'
              ].map(header => (
                <th key={header} className="border border-gray-200 p-3 text-left font-semibold text-gray-600">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {eventos
              .filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin)
              .map((evento, index) => {
                const statusColors = {
                  'Formateado': 'bg-blue-50',
                  'Formulario': 'bg-yellow-50',
                  'Nada': 'bg-red-50',
                  'Entregado': 'bg-green-50'
                };
                
                const eventDate = evento.fechaBoda;
                const today = new Date();
                const event = eventDate ? new Date(eventDate.split(' ')[0]) : null;
                const diffTime = event ? event - today : null;
                const diffWeeks = diffTime ? Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7)) : null;
                
                const isSevenWeeks = diffWeeks === 7;
                const rowColor = isSevenWeeks ? 'bg-yellow-200' : statusColors[evento.fechaFin] || '';
                
                return (
                  <tr key={index} className={`${rowColor} hover:bg-opacity-75 transition duration-200`}>
                    <td className="border border-gray-200 p-3">{evento.idEvento}</td>
                    <td className="border border-gray-200 p-3">{evento.nombre}</td>
                    <td className="border border-gray-200 p-3">{evento.numComprador}</td>
                    <td className="border border-gray-200 p-3">{evento.tipoEvento}</td>
                    <td className="border border-gray-200 p-3">{evento.paquete}</td>
                    <td className="border border-gray-200 p-3">{evento.fechaInicio}</td>
                    <td className="border border-gray-200 p-3">{evento.fechaFin}</td>
                    <td className="border border-gray-200 p-3">{evento.monto}</td>
                    <td className="border border-gray-200 p-3">{evento.detalles}</td>
                    <td className="border border-gray-200 p-3">{evento.total}</td>
                    <td className="border border-gray-200 p-3">{evento.socio}</td>
                    <td className="border border-gray-200 p-3">
                      <span className={`px-2 py-1 rounded-full ${evento.enableEvento ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {evento.enableEvento ? 'Sí' : 'No'}
                      </span>
                    </td>
                    <td className="border border-gray-200 p-3">
                      {evento.fechaBoda || 'No disponible'}
                    </td>
                    <td className="border border-gray-200 p-3">
                      {diffWeeks !== null ? (
                        <span className={`px-2 py-1 rounded-full ${isSevenWeeks ? 'bg-yellow-100 text-yellow-800 font-bold' : ''}`}>
                          {diffWeeks} semanas
                        </span>
                      ) : 'No disponible'}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <p className="mt-6 text-lg font-semibold text-gray-700">
        Total de eventos: {eventos.filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin).length}
      </p>
    </div>
  );
};

export default EventosMontoMenor; 