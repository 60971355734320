import React from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const RegalosForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          MESA DE REGALOS
        </h2>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
            >
              <div className="grid grid-cols-1 gap-6">
                <div className="mb-4">
                  <label
                    htmlFor="cantidadRegalos"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Cantidad:
                  </label>
                  <Input
                    type="number"
                    id="cantidadRegalos"
                    name="cantidadRegalos"
                    value={values?.cantidadRegalos}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="titulo"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Título:
                  </label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={values?.titulo}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="descripcion"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Descripción:
                  </label>
                  <Input
                    type="textarea"
                    id="descripcion"
                    name="descripcion"
                    value={values?.descripcion}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                {[1, 2, 3].map((num) => (
                  <div key={num} className="mb-4">
                    <label
                      htmlFor={`link${num}`}
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Enlace {num}:
                    </label>
                    <Input
                      type="text"
                      id={`link${num}`}
                      name={`link${num}`}
                      value={values?.[`link${num}`]}
                      onChange={handleChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <div className="mt-2">
                      <label className="inline-flex items-center">
                        <Input
                          type="checkbox"
                          id={`link${num}Enable`}
                          name={`link${num}Enable`}
                          checked={values?.[`link${num}Enable`]}
                          onChange={handleChange}
                          className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <span className="ml-2 text-gray-700">Habilitar</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                {[1, 2, 3, 4].map((num) => (
                  <div key={num} className="mb-4">
                    <label
                      htmlFor={`imgLink${num}`}
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Imagen Enlace {num}:
                    </label>
                    <Input
                      type="text"
                      id={`imgLink${num}`}
                      name={`imgLink${num}`}
                      value={values?.[`imgLink${num}`]}
                      onChange={handleChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-1 gap-6 mt-6">
                <div className="mb-4">
                  <label
                    htmlFor="textColor"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Color Texto:
                  </label>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="imgFondo"
                    className="block text-gray-700 text-sm font-bold mb-2"
                  >
                    Fondo Imagen:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${values?.imgFondo ? "input-error" : ""}`}
                  />
                </div>
              </div>

              <div className="mb-6 mt-6">
                <SelectTipoLetra values={values} handleChange={handleChange} />
              </div>

              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Guardar Mesa de Regalos
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 