import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const CeremoniaForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          CEREMONIA
        </h2>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="ceremoniaEnable" className="flex items-center">
                    <Input
                      type="checkbox"
                      id="ceremoniaEnable"
                      name="ceremoniaEnable"
                      checked={values?.ceremoniaEnable}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <span className="text-sm font-medium text-gray-700">
                      Ceremonia Habilitada
                    </span>
                  </label>
                  <ErrorMessage
                    name="ceremoniaEnable"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaImg" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de Ceremonia:
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaImg"
                    name="ceremoniaImg"
                    value={values?.ceremoniaImg}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaImg"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaTitulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Titulo de Ceremonia:
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaTitulo"
                    name="ceremoniaTitulo"
                    value={values?.ceremoniaTitulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaTitulo"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaFecha" className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha de Ceremonia:
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaFecha"
                    name="ceremoniaFecha"
                    value={values?.ceremoniaFecha}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaFecha"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaHora" className="block text-sm font-medium text-gray-700 mb-1">
                    Hora de Ceremonia:
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaHora"
                    name="ceremoniaHora"
                    value={values?.ceremoniaHora}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaHora"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaLugarMap" className="block text-sm font-medium text-gray-700 mb-1">
                    Direccion de Ceremonia (Mapa):
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaLugarMap"
                    name="ceremoniaLugarMap"
                    value={values?.ceremoniaLugarMap}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaLugarMap"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">
                    Nombre del Lugar de Ceremonia:
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaNombreLugar"
                    name="ceremoniaNombreLugar"
                    value={values?.ceremoniaNombreLugar}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaNombreLugar"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="ceremoniaUrl" className="block text-sm font-medium text-gray-700 mb-1">
                    URL de Ceremonia:
                  </label>
                  <Input
                    type="text"
                    id="ceremoniaUrl"
                    name="ceremoniaUrl"
                    value={values?.ceremoniaUrl}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="ceremoniaUrl"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="dobleEnable" className="flex items-center">
                    <Input
                      type="checkbox"
                      id="dobleEnable"
                      name="dobleEnable"
                      checked={values?.dobleEnable}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <span className="text-sm font-medium text-gray-700">
                      Doble Habilitado
                    </span>
                  </label>
                  <ErrorMessage
                    name="dobleEnable"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionEnable" className="flex items-center">
                    <Input
                      type="checkbox"
                      id="recepcionEnable"
                      name="recepcionEnable"
                      checked={values?.recepcionEnable}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <span className="text-sm font-medium text-gray-700">
                      Recepción Habilitada
                    </span>
                  </label>
                  <ErrorMessage
                    name="recepcionEnable"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionImg" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de Recepción:
                  </label>
                  <Input
                    type="text"
                    id="recepcionImg"
                    name="recepcionImg"
                    value={values?.recepcionImg}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionImg"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionTitulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Titulo de Recepción:
                  </label>
                  <Input
                    type="text"
                    id="recepcionTitulo"
                    name="recepcionTitulo"
                    value={values?.recepcionTitulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionTitulo"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionFecha" className="block text-sm font-medium text-gray-700 mb-1">
                    Fecha de Recepción:
                  </label>
                  <Input
                    type="text"
                    id="recepcionFecha"
                    name="recepcionFecha"
                    value={values?.recepcionFecha}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionFecha"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionHora" className="block text-sm font-medium text-gray-700 mb-1">
                    Hora de Recepción:
                  </label>
                  <Input
                    type="text"
                    id="recepcionHora"
                    name="recepcionHora"
                    value={values?.recepcionHora}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionHora"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionLugarMap" className="block text-sm font-medium text-gray-700 mb-1">
                    Direccion de Recepción (Mapa):
                  </label>
                  <Input
                    type="text"
                    id="recepcionLugarMap"
                    name="recepcionLugarMap"
                    value={values?.recepcionLugarMap}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionLugarMap"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionNombreLugar" className="block text-sm font-medium text-gray-700 mb-1">
                    Nombre del Lugar de Recepción:
                  </label>
                  <Input
                    type="text"
                    id="recepcionNombreLugar"
                    name="recepcionNombreLugar"
                    value={values?.recepcionNombreLugar}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionNombreLugar"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="recepcionUrl" className="block text-sm font-medium text-gray-700 mb-1">
                    URL de Recepción:
                  </label>
                  <Input
                    type="text"
                    id="recepcionUrl"
                    name="recepcionUrl"
                    value={values?.recepcionUrl}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage
                    name="recepcionUrl"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>

                <div>
                  <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Color de Texto:
                  </label>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor}
                    onChange={handleChange}
                    className="mt-1 block w-full h-10"
                  />
                </div>

                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Seleccionar imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      values?.imgFondo ? "input-error" : ""
                    }`}
                  />
                </div>

                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>

              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Guardar Ceremonia
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 