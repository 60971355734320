import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getHeaders } from '../../../../components/auth/authHelpers';
import toast from 'react-hot-toast';

const EventosDiferenciaMonto = () => {
  const [eventos, setEventos] = useState([]);
  const [eventosNada, setEventosNada] = useState([]);
  const [resumen, setResumen] = useState({
    sumaMonto: 0,
    sumaTotal: 0,
    sumaDiferencia: 0,
    cantidadEventos: 0
  });
  const [resumenNada, setResumenNada] = useState({
    sumaMonto: 0,
    sumaTotal: 0,
    sumaDiferencia: 0,
    cantidadEventos: 0
  });
  const [loading, setLoading] = useState(true);
  const [tablasExpandidas, setTablasExpandidas] = useState({
    normal: true,
    nada: true
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [responseNormal, responseNada] = await Promise.all([
          axios.get("/api/Eventos/eventos-diferencia-monto", {
            headers: await getHeaders()
          }),
          axios.get("/api/Eventos/eventos-diferencia-monto-nada", {
            headers: await getHeaders()
          })
        ]);
        
        setEventos(responseNormal.data.eventos);
        setResumen(responseNormal.data.resumen);
        setEventosNada(responseNada.data.eventos);
        setResumenNada(responseNada.data.resumen);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error al cargar los eventos");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(value);
  };

  const toggleTabla = (tabla) => {
    setTablasExpandidas(prev => ({
      ...prev,
      [tabla]: !prev[tabla]
    }));
  };

  const TablaEventos = ({ eventos, titulo, tipo }) => (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div 
        className="flex justify-between items-center p-6 bg-gray-50 border-b border-gray-200 cursor-pointer hover:bg-gray-100 transition-colors"
        onClick={() => toggleTabla(tipo)}
      >
        <h2 className="text-xl font-bold">{titulo}</h2>
        <button className="text-gray-500 hover:text-gray-700">
          <svg 
            className={`w-6 h-6 transform transition-transform ${tablasExpandidas[tipo] ? 'rotate-180' : ''}`} 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
      </div>
      <div className={`transition-all duration-300 ease-in-out ${tablasExpandidas[tipo] ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paquete</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Fecha Inicio</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estatus</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monto</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Diferencia</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vendedor</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {eventos.map((evento) => (
                <tr key={evento.idEvento} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.idEvento}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.nombre}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.tipoEvento}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.paquete}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.fechaInicio}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.fechaFin}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-medium">
                    {formatCurrency(evento.monto)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600 font-medium">
                    {formatCurrency(evento.total)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-red-600 font-medium">
                    {formatCurrency(evento.diferencia)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{evento.vendedor}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Resumen Total */}
      <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-indigo-500">
        <h3 className="text-lg font-semibold text-gray-600">Total de Eventos con Diferencia</h3>
        <p className="text-2xl font-bold text-indigo-600">{resumen.cantidadEventos + resumenNada.cantidadEventos} eventos</p>
      </div>

      {/* Resumen Eventos Normales */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-blue-500">
          <h3 className="text-lg font-semibold text-gray-600">Total Montos</h3>
          <p className="text-2xl font-bold text-blue-600">{formatCurrency(resumen.sumaMonto)}</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-green-500">
          <h3 className="text-lg font-semibold text-gray-600">Total Final</h3>
          <p className="text-2xl font-bold text-green-600">{formatCurrency(resumen.sumaTotal)}</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-red-500">
          <h3 className="text-lg font-semibold text-gray-600">Diferencia Total</h3>
          <p className="text-2xl font-bold text-red-600">{formatCurrency(resumen.sumaDiferencia)}</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-purple-500">
          <h3 className="text-lg font-semibold text-gray-600">Cantidad de Eventos</h3>
          <p className="text-2xl font-bold text-purple-600">{resumen.cantidadEventos}</p>
        </div>
      </div>

      {/* Tabla de Eventos Normales */}
      <TablaEventos 
        eventos={eventos} 
        titulo="Eventos con Diferencia (Estatus ≠ Nada)" 
        tipo="normal"
      />

      {/* Resumen Eventos Nada */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-blue-500">
          <h3 className="text-lg font-semibold text-gray-600">Total Montos (Nada)</h3>
          <p className="text-2xl font-bold text-blue-600">{formatCurrency(resumenNada.sumaMonto)}</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-green-500">
          <h3 className="text-lg font-semibold text-gray-600">Total Final (Nada)</h3>
          <p className="text-2xl font-bold text-green-600">{formatCurrency(resumenNada.sumaTotal)}</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-red-500">
          <h3 className="text-lg font-semibold text-gray-600">Diferencia Total (Nada)</h3>
          <p className="text-2xl font-bold text-red-600">{formatCurrency(resumenNada.sumaDiferencia)}</p>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md border-l-4 border-purple-500">
          <h3 className="text-lg font-semibold text-gray-600">Cantidad de Eventos (Nada)</h3>
          <p className="text-2xl font-bold text-purple-600">{resumenNada.cantidadEventos}</p>
        </div>
      </div>

      {/* Tabla de Eventos Nada */}
      <TablaEventos 
        eventos={eventosNada} 
        titulo="Eventos con Diferencia (Estatus = Nada)" 
        tipo="nada"
      />
    </div>
  );
};

export default EventosDiferenciaMonto; 