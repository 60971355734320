import axios from 'axios';
import Swal from 'sweetalert2';

export const checkSpelling = async (text) => {
  try {
    const response = await axios.post('https://api.languagetool.org/v2/check', {
      text: text,
      language: 'es',
      enabledOnly: false
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (response.data.matches.length > 0) {
      let currentText = text;
      
      for (const match of response.data.matches) {
        const error = {
          message: match.message,
          suggestion: match.replacements[0]?.value || '',
          original: match.context.text.substring(match.context.offset, match.context.offset + match.context.length),
          offset: match.offset,
          length: match.length
        };

        const result = await Swal.fire({
          title: 'Error ortográfico encontrado',
          html: `
            <div style="text-align: left">
              <p>Se encontró el siguiente error:</p>
              <p>"${error.original}" → Sugerencia: "${error.suggestion}"</p>
              <p><small>${error.message}</small></p>
            </div>
          `,
          icon: 'warning',
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: 'Corregir',
          denyButtonText: 'Omitir',
          cancelButtonText: 'Cancelar todo',
          customClass: {
            confirmButton: 'swal2-confirm bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg mx-2',
            denyButton: 'swal2-deny bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg mx-2',
            cancelButton: 'swal2-cancel bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg mx-2'
          },
          buttonsStyling: false
        });

        if (result.isDismissed) {
          return { cancelled: true, text: currentText };
        } else if (result.isConfirmed) {
          currentText = applyCorrection(currentText, error);
        }
      }

      await Swal.fire({
        title: 'Revisión completada',
        text: 'Se ha completado la revisión ortográfica.',
        icon: 'success',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'swal2-confirm bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg'
        },
        buttonsStyling: false
      });

      return { cancelled: false, text: currentText };
    } else {
      await Swal.fire({
        title: '¡Texto correcto!',
        text: 'No se encontraron errores ortográficos.',
        icon: 'success',
        confirmButtonText: 'Excelente',
        customClass: {
          confirmButton: 'swal2-confirm bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg'
        },
        buttonsStyling: false
      });
      return { cancelled: false, text };
    }
  } catch (error) {
    console.error('Error checking spelling:', error);
    await Swal.fire({
      title: 'Error',
      text: 'Hubo un error al verificar la ortografía. Por favor, intenta de nuevo.',
      icon: 'error',
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'swal2-confirm bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg'
      },
      buttonsStyling: false
    });
    return { cancelled: true, text };
  }
};

const applyCorrection = (originalText, error) => {
  const before = originalText.substring(0, error.offset);
  const after = originalText.substring(error.offset + error.length);
  
  // Verificar si necesitamos agregar espacios
  const needsSpaceBefore = before.length > 0 && !before.endsWith(' ') && !error.suggestion.startsWith(' ');
  const needsSpaceAfter = after.length > 0 && !after.startsWith(' ') && !error.suggestion.endsWith(' ');
  
  // Construir el texto corregido con los espacios necesarios
  let correctedText = before;
  if (needsSpaceBefore) correctedText += ' ';
  correctedText += error.suggestion;
  if (needsSpaceAfter) correctedText += ' ';
  correctedText += after;
  
  return correctedText;
}; 