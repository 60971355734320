// Function to get token from localStorage
export const getAuthToken = () => {
    var token = JSON.parse(localStorage.getItem('token'));
  return token.token;
};

// Function to get headers with token
export const getHeaders = async () => {
  const token = await getAuthToken();
  return {
    Authorization: token ? `Bearer ${token}` : '',
  };
};

// Function to handle logout
export const handleLogout = () => {
  localStorage.removeItem("token");
  window.location.reload();
}; 