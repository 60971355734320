import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export const SpellingTest = () => {

  return (
    <div>
    
    </div>
  );
}; 