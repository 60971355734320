import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getWeekDates } from '../utils/dateUtils';

const HistorialSemanal = ({ selectedWeek }) => {
  const [historialSemanal, setHistorialSemanal] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [weekDates, setWeekDates] = useState({ start: null, end: null });

  useEffect(() => {
    const fetchHistorial = async () => {
      try {
        const response = await axios.get("/api/Eventos/historial-semanal", {
          params: { year: 2024, weekNumber: selectedWeek },
        });
        setHistorialSemanal(response.data);
        console.log("historial", response.data);
      } catch (error) {
        console.error("Error fetching weekly history data", error);
      }
    };

    fetchHistorial();
    setWeekDates(getWeekDates(2024, selectedWeek));
  }, [selectedWeek]);

  if (!historialSemanal) return <div>Cargando...</div>;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {historialSemanal && Object.entries(historialSemanal).map(([vendedor, datos], index) => {
        const filteredEventos = selectedDate 
          ? datos.eventos.filter(evento => evento.fecha.split('T')[0] === selectedDate)
          : datos.eventos;
          
        return (
          <div key={index} className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300">
            <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
              <span className="h-8 w-2 bg-blue-500 rounded-full mr-3"></span>
              Historial de {datos.nombre}
            </h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <input 
                  type="date" 
                  className="flex-grow p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
                {selectedDate && (
                  <button 
                    className="p-3 text-blue-500 hover:text-blue-700 hover:bg-blue-50 rounded-lg transition duration-300"
                    onClick={() => setSelectedDate('')}
                  >
                    Mostrar todos
                  </button>
                )}
              </div>
              <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Fecha</th>
                      <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Nombre</th>
                      <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Tipo</th>
                      <th className="border border-gray-200 p-3 text-left font-semibold text-gray-600">Evento Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEventos.map((evento, eventoIndex) => (
                      <tr key={eventoIndex} className="hover:bg-gray-50 transition duration-200">
                        <td className="border border-gray-200 p-3">
                          {new Date(evento.fecha).toLocaleDateString('es-MX', {
                            year: 'numeric',
                            month: '2-digit', 
                            day: '2-digit'
                          })}
                        </td>
                        <td className="border border-gray-200 p-3">{evento.nombre}</td>
                        <td className="border border-gray-200 p-3">{evento.tipo}</td>
                        <td className="border border-gray-200 p-3">{evento.evento}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="text-lg font-semibold text-gray-700">
                Total de eventos: {filteredEventos.length}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HistorialSemanal; 