import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const VestimentaForm = ({ initialValues, onSubmit }) => {
  const [resetMessage, setResetMessage] = useState(null);

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          VESTIMENTA
        </h2>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="vestimenta" className="block text-sm font-medium text-gray-700 mb-1">
                    Vestimenta:
                  </label>
                  <Input
                    type="text"
                    id="vestimenta"
                    name="vestimenta"
                    value={values?.vestimenta}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="vestimenta" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="messageVestimenta" className="block text-sm font-medium text-gray-700 mb-1">
                    Mensaje sobre la vestimenta:
                  </label>
                  <Input
                    type="text"
                    id="messageVestimenta"
                    name="messageVestimenta"
                    value={values?.messageVestimenta}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="messageVestimenta" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${values?.imgFondo ? "input-error" : ""}`}
                  />
                  <ErrorMessage name="imgFondo" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="urlVestimentaHombre" className="block text-sm font-medium text-gray-700 mb-1">
                    URL de vestimenta para hombre:
                  </label>
                  <Input
                    type="text"
                    id="urlVestimentaHombre"
                    name="urlVestimentaHombre"
                    value={values?.urlVestimentaHombre}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="urlVestimentaHombre" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="urlVestimentaMujer" className="block text-sm font-medium text-gray-700 mb-1">
                    URL de vestimenta para mujer:
                  </label>
                  <Input
                    type="text"
                    id="urlVestimentaMujer"
                    name="urlVestimentaMujer"
                    value={values?.urlVestimentaMujer}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="urlVestimentaMujer" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="textoHombre" className="block text-sm font-medium text-gray-700 mb-1">
                    Texto para Hombre:
                  </label>
                  <Input
                    type="text"
                    id="textoHombre"
                    name="textoHombre"
                    value={values?.textoHombre}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="textoHombre" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="textoMujer" className="block text-sm font-medium text-gray-700 mb-1">
                    Texto para Mujer:
                  </label>
                  <Input
                    type="text"
                    id="textoMujer"
                    name="textoMujer"
                    value={values?.textoMujer}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="textoMujer" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="colores" className="block text-sm font-medium text-gray-700 mb-1">
                    Colores:
                  </label>
                  <div className="flex items-center space-x-4 mb-4">
                    <button
                      type="button"
                      onClick={() => {
                        handleChange({
                          target: {
                            name: 'colores',
                            value: JSON.stringify({
                              color1: "#FFFFFF",
                              color2: "#4A3728",
                              color3: "#D2B48C",
                              color4: "#1A1A1A"
                            })
                          }
                        });
                        setResetMessage("Colores habilitados");
                        setTimeout(() => setResetMessage(null), 3000);
                      }}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                      Habilitar Colores
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleChange({
                          target: {
                            name: 'colores',
                            value: null
                          }
                        });
                        setResetMessage("Colores restablecidos");
                        setTimeout(() => setResetMessage(null), 3000);
                      }}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Restablecer Colores
                    </button>
                    {resetMessage && (
                      <div className="text-sm text-green-600 transition-opacity duration-300">
                        {resetMessage}
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="color1" className="block text-xs font-medium text-gray-600">
                        Color 1:
                      </label>
                      <Input
                        type="color"
                        id="color1"
                        name="color1"
                        disabled={!values?.colores}
                        value={values?.colores ? JSON.parse(values.colores).color1 : "#FFFFFF"}
                        onChange={(e) => {
                          const currentColors = values?.colores ? JSON.parse(values.colores) : {
                            color1: "#FFFFFF",
                            color2: "#4A3728",
                            color3: "#D2B48C",
                            color4: "#1A1A1A"
                          };
                          handleChange({
                            target: {
                              name: 'colores',
                              value: JSON.stringify({
                                ...currentColors,
                                color1: e.target.value
                              })
                            }
                          });
                        }}
                        className="mt-1 block w-full h-8"
                      />
                    </div>
                    <div>
                      <label htmlFor="color2" className="block text-xs font-medium text-gray-600">
                        Color 2:
                      </label>
                      <Input
                        type="color"
                        id="color2"
                        name="color2"
                        disabled={!values?.colores}
                        value={values?.colores ? JSON.parse(values.colores).color2 : "#FFFFFF"}
                        onChange={(e) => {
                          const currentColors = values?.colores ? JSON.parse(values.colores) : {
                            color1: "#FFFFFF",
                            color2: "#4A3728",
                            color3: "#D2B48C",
                            color4: "#1A1A1A"
                          };
                          handleChange({
                            target: {
                              name: 'colores',
                              value: JSON.stringify({
                                ...currentColors,
                                color2: e.target.value
                              })
                            }
                          });
                        }}
                        className="mt-1 block w-full h-8"
                      />
                    </div>
                    <div>
                      <label htmlFor="color3" className="block text-xs font-medium text-gray-600">
                        Color 3:
                      </label>
                      <Input
                        type="color"
                        id="color3"
                        name="color3"
                        disabled={!values?.colores}
                        value={values?.colores ? JSON.parse(values.colores).color3 : "#FFFFFF"}
                        onChange={(e) => {
                          const currentColors = values?.colores ? JSON.parse(values.colores) : {
                            color1: "#FFFFFF",
                            color2: "#4A3728",
                            color3: "#D2B48C",
                            color4: "#1A1A1A"
                          };
                          handleChange({
                            target: {
                              name: 'colores',
                              value: JSON.stringify({
                                ...currentColors,
                                color3: e.target.value
                              })
                            }
                          });
                        }}
                        className="mt-1 block w-full h-8"
                      />
                    </div>
                    <div>
                      <label htmlFor="color4" className="block text-xs font-medium text-gray-600">
                        Color 4:
                      </label>
                      <Input
                        type="color"
                        id="color4"
                        name="color4"
                        disabled={!values?.colores}
                        value={values?.colores ? JSON.parse(values.colores).color4 : "#FFFFFF"}
                        onChange={(e) => {
                          const currentColors = values?.colores ? JSON.parse(values.colores) : {
                            color1: "#FFFFFF",
                            color2: "#4A3728",
                            color3: "#D2B48C",
                            color4: "#1A1A1A"
                          };
                          handleChange({
                            target: {
                              name: 'colores',
                              value: JSON.stringify({
                                ...currentColors,
                                color4: e.target.value
                              })
                            }
                          });
                        }}
                        className="mt-1 block w-full h-8"
                      />
                    </div>
                  </div>
                  <ErrorMessage name="colores" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Color de texto:
                  </label>
                  <Input
                    type="text"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="textColor" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Guardar Vestimenta
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 