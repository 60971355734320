import React from 'react';
import { Label, Input } from 'reactstrap';
import { ImageFormComponent } from '../../Images/ImageFormComponent';

export const InstagramPostsForm = ({ values, handleChange, handleInputChange, errors, touched, setFieldValue }) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label htmlFor="instagramTitulo" className="block text-sm font-medium text-gray-700 mb-1">
            Título
          </Label>
          <Input
            id="instagramTitulo"
            name="instagramTitulo"
            type="text"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.instagramTitulo || ''}
            className="w-full rounded-md"
            placeholder="Título para la sección de Instagram"
          />
        </div>

        <div>
          <Label htmlFor="instagramHashtag" className="block text-sm font-medium text-gray-700 mb-1">
            Hashtag
          </Label>
          <Input
            id="instagramHashtag"
            name="instagramHashtag"
            type="text"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.instagramHashtag || ''}
            className="w-full rounded-md"
            placeholder="#TuHashtag"
          />
        </div>
      </div>

      <div>
        <Label htmlFor="instagramDescripcion" className="block text-sm font-medium text-gray-700 mb-1">
          Descripción
        </Label>
        <textarea
          id="instagramDescripcion"
          name="instagramDescripcion"
          rows="4"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.instagramDescripcion || ''}
          className="w-full rounded-md border-gray-300"
          placeholder="Descripción para la sección de Instagram"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Label htmlFor="instagramTextColor" className="block text-sm font-medium text-gray-700 mb-1">
            Color del Texto
          </Label>
          <Input
            id="instagramTextColor"
            name="instagramTextColor"
            type="color"
            onChange={(e) => { handleChange(e); handleInputChange(e); }}
            value={values.instagramTextColor || '#000000'}
            className="h-10 w-full"
          />
        </div>
      </div>

      
      <ImageFormComponent
        label="Foto para fondo de Instagram"
        field="instaBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        edit={false}
      />

      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
        <p className="text-blue-700">
          <span className="font-bold">Nota:</span> Esta sección te permitirá configurar cómo se mostrarán las publicaciones de Instagram relacionadas con tu evento.
        </p>
      </div>
    </div>
  );
}; 