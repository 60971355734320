import React, { useState, useEffect } from "react";
import axios from "axios";
import { useApi } from '../hooks/useApi';

const AdminTable = () => {
  const { loading, error, callApi } = useApi();
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newAdmin, setNewAdmin] = useState({
    enable: "true",
    nombre: "",
    user: "",
    pass: "",
    token: "",
  });
  const [editAdmin, setEditAdmin] = useState(null); // State for editing
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal state

  const fetchAdmins = async () => {
    try {
      setIsLoading(true);
      const response = await callApi('get', '/Admins');
      if (response) {
        console.log('Datos recibidos:', response);
        setAdmins(response);
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 p-4">
        Error: {error}
      </div>
    );
  }

  // Add admin
  const addAdmin = async () => {
    await callApi('post', '/Admins', newAdmin)
      .then((response) => {
        setAdmins([...admins, response.data]);
        setNewAdmin({
          enable: "true",
          nombre: "",
          user: "",
          pass: "",
          token: "",
        });
      })
      .catch((error) => console.error("Error adding admin:", error));
  };

  // Delete admin
  const deleteAdmin = async (id) => {
    const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este administrador? Esta acción no se puede deshacer.");
    if (confirmDelete) {
      await callApi('delete', `/Admins/${id}`)
        .then(() => setAdmins(admins.filter((admin) => admin.idAdmin !== id)))
        .catch((error) => console.error("Error deleting admin:", error));
    }
  };

  // Open edit modal
  const openEditModal = (admin) => {
    setEditAdmin(admin);
    setIsEditModalOpen(true);
  };

  // Update admin
  const updateAdmin = async () => {
    await callApi('put', `/Admins/${editAdmin.idAdmin}`, editAdmin)
      .then((response) => {
        setAdmins(
          admins.map((admin) =>
            admin.idAdmin === editAdmin.idAdmin ? response.data : admin
          )
        );
        window.location.reload();
        setIsEditModalOpen(false);
        setEditAdmin(null);
      })
      .catch((error) => console.error("Error updating admin:", error));
  };

  // Handle input changes for adding and editing
  const handleInputChange = (e, isEdit = false) => {
    const { name, value } = e.target;
    if (isEdit) {
      setEditAdmin({ ...editAdmin, [name]: value });
    } else {
      setNewAdmin({ ...newAdmin, [name]: value });
    }
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="bg-white p-6 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Administrar Administradores
        </h2>

        <h3 className="text-xl font-semibold mb-4 text-gray-700">Agregar Admin</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          <input
            type="text"
            name="nombre"
            value={newAdmin.nombre}
            onChange={(e) => handleInputChange(e)}
            placeholder="Nombre"
            className="border px-4 py-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
          <input
            type="text"
            name="user"
            value={newAdmin.user}
            onChange={(e) => handleInputChange(e)}
            placeholder="Usuario"
            className="border px-4 py-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
          <input
            type="password"
            name="pass"
            value={newAdmin.pass}
            onChange={(e) => handleInputChange(e)}
            placeholder="Contraseña"
            className="border px-4 py-2 rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>
        <button
          onClick={addAdmin}
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition duration-300"
        >
          Agregar Admin
        </button>
      </div>

      <div className="mt-8 bg-white p-6 shadow-md rounded-lg">
        <h3 className="text-xl font-semibold text-gray-700 mb-4">Lista de Admins</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border-b">ID</th>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border-b">Nombre</th>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border-b">Usuario</th>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border-b">Contraseña</th>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border-b">Habilitado</th>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border-b">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {admins?.map((admin) => (
                <tr key={admin.idAdmin} className="hover:bg-gray-100">
                  <td className="px-4 py-2 border-b text-gray-600">{admin.idAdmin}</td>
                  <td className="px-4 py-2 border-b text-gray-600">{admin.nombre}</td>
                  <td className="px-4 py-2 border-b text-gray-600">{admin.user}</td>
                  <td className="px-4 py-2 border-b text-gray-600">{admin.pass}</td>
                  <td className="px-4 py-2 border-b text-gray-600">{admin.enable}</td>
                  <td className="px-4 py-2 border-b space-x-2">
                    <button
                      onClick={() => openEditModal(admin)}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition duration-300"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => deleteAdmin(admin.idAdmin)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-semibold mb-4">Editar Administrador</h3>
            <div className="grid gap-4">
              <input
                type="text"
                name="nombre"
                value={editAdmin.nombre}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Nombre"
                className="border px-4 py-2 rounded focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="user"
                value={editAdmin.user}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Usuario"
                className="border px-4 py-2 rounded focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="password"
                name="pass"
                value={editAdmin.pass}
                onChange={(e) => handleInputChange(e, true)}
                placeholder="Contraseña"
                className="border px-4 py-2 rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
              >
                Cancelar
              </button>
              <button
                onClick={updateAdmin}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTable;
