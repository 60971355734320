import React from 'react';

export const FormattedText = ({ text }) => {
  if (!text) return null;
  
  return text.split("\\n").map((linea, index) => (
    <React.Fragment key={index}>
      {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
        parte.startsWith("/") && parte.endsWith("/") ? (
          <strong key={subIndex}>{parte.slice(1, -1)}</strong>
        ) : (
          <span key={subIndex}>{parte}</span>
        )
      ))}
      <br />
    </React.Fragment>
  ));
};

export default FormattedText; 