import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const VentasDiarias = ({ selectedMonth, selectedYear }) => {
  const [dataDiaria, setDataDiaria] = useState([]);
  const [ultimoDatoDiaria, setUltimoDatoDiaria] = useState(null);
  const [totalVentas, setTotalVentas] = useState(0);
  const [totalMontos, setTotalMontos] = useState(0);
  const [promedioVentasDiarias, setPromedioVentasDiarias] = useState(0);
  const [totalEventos, setTotalEventos] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/Eventos/ventas-diarias", {
          params: { year: selectedYear, month: selectedMonth },
        });
        
        if (response.data && Array.isArray(response.data)) {
          setDataDiaria(response.data);
          
          if (response.data.length > 0) {
            setUltimoDatoDiaria(response.data[response.data.length - 1]);
          }
          
          // Calcular totales
          const totalVentas = response.data.reduce((sum, day) => sum + (day.totalTotales || 0), 0);
          const totalMontos = response.data.reduce((sum, day) => sum + (day.totalMontos || 0), 0);
          const totalEventos = response.data.reduce((sum, day) => sum + (day.cantidadEventos || 0), 0);
          
          setTotalVentas(totalVentas);
          setTotalMontos(totalMontos);
          setTotalEventos(totalEventos);
          setPromedioVentasDiarias(response.data.length > 0 ? totalVentas / response.data.length : 0);
        } else {
          setDataDiaria([]);
          setUltimoDatoDiaria(null);
          setTotalVentas(0);
          setTotalMontos(0);
          setTotalEventos(0);
          setPromedioVentasDiarias(0);
        }
      } catch (error) {
        console.error("Error fetching data", error);
        setDataDiaria([]);
        setUltimoDatoDiaria(null);
        setTotalVentas(0);
        setTotalMontos(0);
        setTotalEventos(0);
        setPromedioVentasDiarias(0);
      }
    };

    fetchData();
  }, [selectedMonth, selectedYear]);

  if (!dataDiaria || dataDiaria.length === 0) {
    return <div>No hay datos disponibles para mostrar</div>;
  }

  const chartDataDiaria = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Totales",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.totalTotales || 0,
          totalMontos: d.totalMontos || 0,
          cantidadEventos: d.cantidadEventos || 0,
        })),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Montos",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.totalMontos || 0,
          cantidadEventos: d.cantidadEventos || 0,
        })),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      }
    ],
  };

  const chartDataDiariaVendedoras = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Mariana",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.vendedoras?.mariana?.total || 0,
          eventos: d.vendedoras?.mariana?.eventos || 0
        })),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
      {
        label: "Camila",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.vendedoras?.camila?.total || 0,
          eventos: d.vendedoras?.camila?.eventos || 0
        })),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
      {
        label: "Jaimes",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.vendedoras?.jaimes?.total || 0,
          eventos: d.vendedoras?.jaimes?.eventos || 0
        })),
        backgroundColor: "rgba(255, 206, 86, 0.6)",
      },
      {
        label: "Otros",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.vendedoras?.otro?.total || 0,
          eventos: d.vendedoras?.otro?.eventos || 0
        })),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      }
    ],
  };

  const optionsVentasDiariasTotal = {
    plugins: {
      title: {
        display: true,
        text: 'Ventas Diarias',
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataPoint = context.raw;
            const label = context.dataset.label;
            if (label === "Totales") {
              return [
                `Total: $${dataPoint.y.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                `Monto: $${dataPoint.totalMontos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                `Eventos: ${dataPoint.cantidadEventos || 0}`
              ];
            } else {
              return [
                `Monto: $${dataPoint.y.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                `Eventos: ${dataPoint.cantidadEventos || 0}`
              ];
            }
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Monto ($)'
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const optionsVentasDiarias = {
    plugins: {
      title: {
        display: true,
        text: 'Ventas por Vendedor',
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataPoint = context.raw;
            const vendedora = context.dataset.label;
            return [
              `${vendedora}:`,
              `Total: $${dataPoint.y.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
              `Eventos: ${dataPoint.eventos || 0}`
            ];
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Monto Total ($)'
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  
  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {[
          { title: 'Total Ventas', value: `$${totalVentas.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, color: 'blue' },
          { title: 'Total Montos', value: `$${totalMontos.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, color: 'red' },
          { title: 'Promedio Diario', value: `$${promedioVentasDiarias.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, color: 'green' },
          { title: 'Eventos Totales', value: totalEventos, color: 'purple' }
        ].map((stat, index) => (
          <div key={index} className={`bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 border-l-4 border-${stat.color}-500`}>
            <h3 className="text-lg font-semibold text-gray-600 mb-2">{stat.title}</h3>
            <p className={`text-3xl font-bold text-${stat.color}-600`}>{stat.value}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 h-[500px]">
          <div className="h-[400px]">
            <Bar data={chartDataDiaria} options={optionsVentasDiariasTotal} />
          </div>
        </div>
        <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 h-[500px]">
          <div className="h-[400px]">
            <Bar data={chartDataDiariaVendedoras} options={optionsVentasDiarias} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VentasDiarias; 