import React, { useState } from "react";
import axios from "axios";

export const AddHistorial = ({evento, nombre}) => {
    const [tipo, setTipo] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fecha, setFecha] = useState(() => {
        const today = new Date();
        const cdmxOffset = -5 * 60; // CDMX is UTC-5
        const cdmxTime = new Date(today.getTime() + cdmxOffset * 60000);
        return cdmxTime.toISOString().split('T')[0];
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            // Primero verificamos si existe un registro con el mismo evento
            const response = await axios.get("/api/Historial");
            const existingRecord = response.data.find(
                record => record.evento === evento && 
                         record.fecha === fecha && 
                         record.tipo === tipo
            );

            if (existingRecord) {
                const message = `Ya existe un registro con:\nNombre: ${existingRecord.nombre}\nTipo: ${existingRecord.tipo}\nFecha: ${existingRecord.fecha}\nEvento: ${existingRecord.evento}\n\n¿Desea agregar otro registro con los mismos datos?`;
                
                if (!window.confirm(message)) {
                    setIsLoading(false);
                    return;
                }
            }

            // Confirmación final antes de agregar
            if (!window.confirm(`¿Está seguro de agregar este registro?\nTipo: ${tipo}\nFecha: ${fecha}\nEvento: ${evento}`)) {
                setIsLoading(false);
                return;
            }

            // Si no existe o el usuario confirmó, procedemos a crear el nuevo registro
            await axios.post("/api/Historial", { nombre, tipo, fecha, evento });
            alert("Historial agregado exitosamente");
            setTipo("");
            setFecha(() => {
                const today = new Date();
                const cdmxOffset = -5 * 60; // CDMX is UTC-5
                const cdmxTime = new Date(today.getTime() + cdmxOffset * 60000);
                return cdmxTime.toISOString().split('T')[0];
            });
        } catch (error) {
            console.error("Error al agregar historial:", error);
            alert("Error al agregar historial");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-white shadow-sm rounded p-2 text-sm">
            <form onSubmit={handleSubmit} className="flex items-center space-x-2">
                <select
                    id="tipo"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                    required
                    disabled={isLoading}
                    className="border rounded px-2 py-1 text-xs"
                >
                    <option value="">Tipo</option>
                    <option value="Nueva">Nueva</option>
                    <option value="Correccion">Corrección</option>
                    <option value="Express">Express</option>
                    <option value="Urgente">Urgente</option>
                    <option value="Finalizado">Finalizado</option>
                </select>
                <button 
                    type="submit"
                    disabled={isLoading}
                    className={`${
                        isLoading 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-blue-500 hover:bg-blue-600'
                    } text-white px-2 py-1 rounded text-xs`}
                >
                    {isLoading ? 'Agregando...' : 'Agregar'}
                </button>
            </form>
        </div>
    );
};