import { BodaEjemplo } from "../screens/bodas/Boda-oro/BodaEjemplo";
import { BodaEjemploSinFoto } from "../screens/bodas/Boda-oro/BodaEjemploSinFoto";
import { BodaNeftaliYDeysi } from "../screens/bodas/Boda-oro/BodaNEftaliYDeysi";

const RoutesBodaOro = [
    {
      path: '/boda-oro-ejemplo',
      element: <BodaEjemplo />
    },
    {
      path: '/boda-oro-ejemplo-fotos',
      element: <BodaEjemploSinFoto />
    },
    {
      path: '/boda-oro-neftali&deysi',
      element: <BodaNeftaliYDeysi />
    },
]

export default RoutesBodaOro;