import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import confetti from 'canvas-confetti';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ConfirmacionInvitado = ({ idEvento, peltaColores, images, datosSeccion1 }) => {
  const [formData, setFormData] = useState({
    nombreInvitado: '',
    numAccesos: 1
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState({ text: '', isError: false });
  const componentRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: "linear",
  };

  // Font style
  const fontFamilyStyle = { 
    fontFamily: datosSeccion1?.tipoLetra || "ui-serif, Georgia, Cambria, Times New Roman, Times, serif" 
  };

  useEffect(() => {
    if (datosSeccion1?.confetti === true) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
          observer.disconnect();
        }
      },
      { threshold: 0.5 }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, [datosSeccion1?.confetti]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage({ text: '', isError: false });

    try {
      const response = await axios.post('/api/Invitado', {
        idEvento: idEvento,
        nombre: formData.nombreInvitado,
        accesos: formData.numAccesos
      });

      setMessage({
        text: 'Thank you for confirming your attendance!',
        isError: false
      });
      setFormData({ nombreInvitado: '', numAccesos: 1 });
    } catch (error) {
      setMessage({
        text: 'There was an error confirming your attendance. Please try again.',
        isError: true
      });
      console.error('Error registering guest:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      ref={componentRef}
      style={{
        backgroundImage: `url(${datosSeccion1?.imgFondo || ""})`,
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        display: "flex",
        backgroundPosition: "center center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: peltaColores?.color2,
        color: datosSeccion1?.textColor || "",
        ...fontFamilyStyle,
      }}
      className="sm:h-auto md:h-auto flex flex-col px-4 py-[5vh] justify-center items-center w-full"
    >
      <h1
        className="text-center sm:text-[32px] md:text-[42px] mb-6"
        data-aos="flip-up"
        style={fontFamilyStyle}
      >
        {datosSeccion1?.tituloConfirmacion?.split("\\n").map((linea, index) => (
          <React.Fragment key={index}>
            {linea.split(/(\/.*?\/)/).map((parte, subIndex) => (
              parte.startsWith("/") && parte.endsWith("/") ? (
                <strong key={subIndex}>{parte.slice(1, -1)}</strong>
              ) : (
                <span key={subIndex}>{parte}</span>
              )
            ))}
            <br />
          </React.Fragment>
        ))}
      </h1>

      {images && (
        <>
          <Slider {...settings} className="md:w-[40%] sm:w-[80%] mb-8">
            {images.map((imageUrl, index) => (
              <div data-aos="fade-down-right" className="rolImg" key={index}>
                <img
                  src={imageUrl}
                  alt={`Slide ${index + 1}`}
                  className="w-auto rounded-xl max-h-[70vh]"
                />
              </div>
            ))}
          </Slider>
        </>
      )}

      <div className="max-w-md w-full bg-white/80 backdrop-blur-sm p-6 rounded-lg shadow-lg">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label 
              htmlFor="nombreInvitado" 
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Your Full Name
            </label>
            <input
              type="text"
              id="nombreInvitado"
              required
              className="w-full px-4 py-2 border text-gray-700 border-gray-300 rounded-md focus:ring-2 focus:ring-gray-500 focus:border-transparent transition duration-300"
              value={formData.nombreInvitado}
              onChange={(e) => setFormData({ ...formData, nombreInvitado: e.target.value })}
              placeholder="Enter your full name"
            />
          </div>

          <div>
            <label 
              htmlFor="numAccesos" 
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Number of People Attending
            </label>
            <select
              id="numAccesos"
              required
              className="w-full px-4 py-2 border text-gray-700 border-gray-300 rounded-md focus:ring-2 focus:ring-gray-500 focus:border-transparent transition duration-300"
              value={formData.numAccesos}
              onChange={(e) => setFormData({ ...formData, numAccesos: Number(e.target.value) })}
            >
              {[1, 2, 3, 4, 5].map(num => (
                <option className="text-gray-700" key={num} value={`${num}`}>
                  {num} {num === 1 ? 'person' : 'people'}
                </option>
              ))}
            </select>
            <p className="mt-1 text-sm text-gray-500">
              Select if you will attend alone or with a companion
            </p>
          </div>

          {message.text && (
            <div className={`p-4 rounded-md ${
              message.isError ? 'bg-red-100 text-gray-700' : 'bg-green-100 text-green-700'
            }`}>
              {message.text}
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full py-3 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-700 transition duration-300 ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? 'Confirming...' : 'Confirm Attendance'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ConfirmacionInvitado;