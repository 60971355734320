import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { baseChartOptions } from '../types/chartOptions';

const VentasQuincenales = ({ selectedYear }) => {
  const [dataQuincenal, setDataQuincenal] = useState(null);

  useEffect(() => {
    const fetchVentasQuincenales = async () => {
      try {
        const response = await axios.get("/api/Eventos/ventas-quincenales", {
          params: { year: selectedYear },
        });
        setDataQuincenal(response.data);
      } catch (error) {
        console.error("Error fetching biweekly sales data", error);
      }
    };

    fetchVentasQuincenales();
  }, [selectedYear]);

  if (!dataQuincenal) return <div>Cargando...</div>;

  const chartData = {
    labels: [...new Set(dataQuincenal.map(d => `Mes ${d.mes}`))],
    datasets: dataQuincenal.map((d, index) => ({
      label: `Quincena ${d.quincena}`,
      data: [
        {
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          fechaInicio: d.fechaInicio,
          fechaFin: d.fechaFin,
        },
      ],
      backgroundColor: `rgba(${255 - index * 40}, ${159 - index * 20}, 64, 0.6)`,
    })),
  };

  return (
    <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition duration-300 h-[500px]">
      <div className="h-[400px]">
        <Bar data={chartData} options={baseChartOptions} />
      </div>
    </div>
  );
};

export default VentasQuincenales; 