export const getWeekNumber = (d) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  return weekNo;
};

export const getWeekDates = (year, weekNumber) => {
  const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const dow = simple.getDay();
  const startDate = simple;
  if (dow <= 4)
    startDate.setDate(simple.getDate() - simple.getDay() + 1);
  else
    startDate.setDate(simple.getDate() + 8 - simple.getDay());
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 6);
  return { start: startDate, end: endDate };
}; 