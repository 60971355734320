import { BodaAutomatica } from "../screens/bodas/Boda-automatica/BodaAutomatica";
import { BodaAutomaticaDiamCivil } from "../screens/bodas/Boda-automatica/BodaAutomaticaDiamCivil";
import { BodaAutomaticaDiamante } from "../screens/bodas/Boda-automatica/BodaAutomaticaDiamante";
import { BodaAutomaticaDiamante as BodaAutomaticaDiamanteEn } from "../screens/bodas/Boda-automatica/ingles/BodaAutomaticaDiamante";
import { BodaLupita } from "../screens/bodas/Boda-automatica/BodaLupita";
import { BodaAutomatica as BodaAutomaticaEn } from "../screens/bodas/Boda-automatica/ingles/BodaAutomatica";
import { BodaAutomatica2 } from "../screens/bodas/Boda-automatica/BodaAutomatica2";
import { BodaAutomaticaDiamante2 } from "../screens/bodas/Boda-automatica/BodaAutomaticaDiamante2";
import BodaAutoDiamanteGuest from "../screens/bodas/Boda-automatica/ingles/BodaAutoDiamanteGuest";
import { FormularioAutoBodaEn } from "../screens/Admin/FormsComponent/ingles/FormularioAutoBodaEn";
const RutaAutomaticaBoda = [
    {
        path: '/boda-auto',
        element: <BodaAutomatica />
    },
    {
        path: '/wedding-form',
        element: <FormularioAutoBodaEn />
    },
    {
        path: '/2/boda-auto',
        element: <BodaAutomatica2 />
    },
    {
        path: '/en/wedding-auto',
        element: <BodaAutomaticaEn />
    },
    {
        path: '/boda-auto-diamante',
        element: <BodaAutomaticaDiamante />
    },
    {
        path: '/diamond-guest',
        element: <BodaAutoDiamanteGuest />
    },
    {
        path: '/2/boda-auto-diamante',
        element: <BodaAutomaticaDiamante2 />
    },
    {
        path: '/2/diamond-wedding',
        element: <BodaAutomaticaDiamante2 />
    },
    {
        path: '/wedding-auto-diamond',
        element: <BodaAutomaticaDiamanteEn />
    },
    {
        path: '/boda-auto-lupita-manuel',
        element: <BodaLupita />
    },
    {
        path: '/boda-auto-diamante-cv',
        element: <BodaAutomaticaDiamCivil />
    },
]


export default RutaAutomaticaBoda;