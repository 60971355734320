import { useState, useCallback } from 'react';
import api from '../services/api';

export const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = useCallback(async (method, endpoint, requestData = null) => {
    setLoading(true);
    setError(null);
    
    try {
      const result = await api[method](endpoint, requestData);
      setLoading(false);
      return result; // api.js ya devuelve response.data
    } catch (err) {
      setError(err.response?.data || 'Error en la petición');
      setLoading(false);
      throw err;
    }
  }, []);

  return {
    loading,
    error,
    callApi
  };
}; 