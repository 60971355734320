import React from 'react';
import { Formik, Form } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const PadresForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h3 className="text-2xl font-semibold mb-6 text-gray-700">Padres y Padrinos</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label htmlFor="cantidad" className="block text-sm font-medium text-gray-700 mb-1">
                    Cantidad:
                  </label>
                  <Input
                    type="number"
                    id="cantidad"
                    name="cantidad"
                    value={values?.cantidad}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="descripcion" className="block text-sm font-medium text-gray-700 mb-1">
                    Descripción:
                  </label>
                  <Input
                    type="text"
                    id="descripcion"
                    name="descripcion"
                    value={values?.descripcion}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Título:
                  </label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={values?.titulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label htmlFor="titulo1" className="block text-sm font-medium text-gray-700 mb-1">
                    Título 1:
                  </label>
                  <Input
                    type="text"
                    id="titulo1"
                    name="titulo1"
                    value={values?.titulo1}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="madre" className="block text-sm font-medium text-gray-700 mb-1">
                    Madre:
                  </label>
                  <Input
                    type="text"
                    id="madre"
                    name="madre"
                    value={values?.madre}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="padre" className="block text-sm font-medium text-gray-700 mb-1">
                    Padre:
                  </label>
                  <Input
                    type="text"
                    id="padre"
                    name="padre"
                    value={values?.padre}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label htmlFor="titulo2" className="block text-sm font-medium text-gray-700 mb-1">
                    Título 2:
                  </label>
                  <Input
                    type="text"
                    id="titulo2"
                    name="titulo2"
                    value={values?.titulo2}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="padrino" className="block text-sm font-medium text-gray-700 mb-1">
                    Padrino:
                  </label>
                  <Input
                    type="text"
                    id="padrino"
                    name="padrino"
                    value={values?.padrino}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="madrina" className="block text-sm font-medium text-gray-700 mb-1">
                    Madrina:
                  </label>
                  <Input
                    type="text"
                    id="madrina"
                    name="madrina"
                    value={values?.madrina}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label htmlFor="titulo3" className="block text-sm font-medium text-gray-700 mb-1">
                    Título 3:
                  </label>
                  <Input
                    type="text"
                    id="titulo3"
                    name="titulo3"
                    value={values?.titulo3}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="padrino2" className="block text-sm font-medium text-gray-700 mb-1">
                    Segundo Padrino:
                  </label>
                  <Input
                    type="text"
                    id="padrino2"
                    name="padrino2"
                    value={values?.padrino2 || ""}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="madrina2" className="block text-sm font-medium text-gray-700 mb-1">
                    Segunda Madrina:
                  </label>
                  <Input
                    type="text"
                    id="madrina2"
                    name="madrina2"
                    value={values?.madrina2 || ""}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Color de Texto:
                  </label>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor || ""}
                    onChange={handleChange}
                    className="mt-1 block w-full h-10"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Seleccionar imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      values?.imgFondo ? "input-error" : ""
                    }`}
                  />
                </div>
                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>

              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                  Guardar Padres y Padrinos
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 