import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const ConfirmacionForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          CONFIRMACIÓN
        </h2>
        <Formik
          initialValues={{
            confirmacionId: initialValues?.confirmacionId || '',
            tituloConfirmacion: initialValues?.tituloConfirmacion || '',
            aviso: initialValues?.aviso || '',
            numeroConfirmacion: initialValues?.numeroConfirmacion || '',
            textoConfirmacion: initialValues?.textoConfirmacion || '',
            textWhatsApp: initialValues?.textWhatsApp || '',
            textColor: initialValues?.textColor || '',
            imgFondo: initialValues?.imgFondo || '',
            numeroNovia: initialValues?.numeroNovia || '',
            numeroNovio: initialValues?.numeroNovio || '',
            enableNum: initialValues?.enableNum || false,
            idEvento: initialValues?.idEvento || '',
            tipoLetra: initialValues?.tipoLetra || '',
            confetti: initialValues?.confetti || false,
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="tituloConfirmacion" className="block text-sm font-medium text-gray-700 mb-1">
                    Título de Confirmación:
                  </label>
                  <Input
                    type="text"
                    id="tituloConfirmacion"
                    name="tituloConfirmacion"
                    value={values?.tituloConfirmacion}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="aviso" className="block text-sm font-medium text-gray-700 mb-1">
                    Aviso:
                  </label>
                  <textarea
                    id="aviso"
                    name="aviso"
                    value={values?.aviso}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    rows="3"
                  />
                </div>

                <div>
                  <label htmlFor="enableNum" className="flex items-center">
                    <Field
                      type="checkbox"
                      id="enableNum"
                      name="enableNum"
                      checked={values?.enableNum}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    <span className="text-sm font-medium text-gray-700">
                      Desactivar Número Confirmación
                    </span>
                  </label>
                </div>

                <div>
                  <label htmlFor="numeroConfirmacion" className="block text-sm font-medium text-gray-700 mb-1">
                    Número de Confirmación:
                  </label>
                  <Input
                    type="text"
                    id="numeroConfirmacion"
                    name="numeroConfirmacion"
                    value={values?.numeroConfirmacion}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="numeroNovia" className="block text-sm font-medium text-gray-700 mb-1">
                    Número de la Novia:
                  </label>
                  <Input
                    type="text"
                    id="numeroNovia"
                    name="numeroNovia"
                    value={values?.numeroNovia}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="numeroNovio" className="block text-sm font-medium text-gray-700 mb-1">
                    Número del Novio:
                  </label>
                  <Input
                    type="text"
                    id="numeroNovio"
                    name="numeroNovio"
                    value={values?.numeroNovio}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="confetti" className="flex items-center">
                    <Input
                      type="checkbox"
                      id="confetti"
                      name="confetti"
                      checked={values?.confetti === true}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: 'confetti',
                            value: e.target.checked
                          }
                        });
                      }}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-700">Desactivar Confetti</span>
                  </label>
                </div>

                <div>
                  <label htmlFor="textColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Color de Texto:
                  </label>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    value={values?.textColor || "#000000"}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="textWhatsApp" className="block text-sm font-medium text-gray-700 mb-1">
                    Texto de Cuadro de WhatsApp:
                  </label>
                  <Input
                    type="text"
                    id="textWhatsApp"
                    name="textWhatsApp"
                    value={values?.textWhatsApp}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="textoConfirmacion" className="block text-sm font-medium text-gray-700 mb-1">
                    Mensaje de Confirmación en WhatsApp:
                  </label>
                  <Input
                    type="text"
                    id="textoConfirmacion"
                    name="textoConfirmacion"
                    value={values?.textoConfirmacion}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Seleccionar imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                      values?.imgFondo ? "input-error" : ""
                    }`}
                  />
                </div>

                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>

              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Guardar Confirmación
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 