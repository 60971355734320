import React from 'react';
import { Label, Input } from 'reactstrap';
import { ImageFormComponent } from '../../Images/ImageFormComponent';

export const VestimentaForm = ({ values, handleChange, handleInputChange, errors, touched, setFieldValue }) => {
  const handleColorChange = (colorKey, color) => {
    const currentColors = values.coloresVestimenta ? 
      JSON.parse(values.coloresVestimenta) : 
      {
        color1: "#FFFFFF",
        color2: "#4A3728",
        color3: "#D2B48C",
        color4: "#1A1A1A"
      };
    
    currentColors[colorKey] = color;
    
    // Convert the object to a string and save it
    const coloresString = JSON.stringify(currentColors);
    setFieldValue('coloresVestimenta', coloresString);
    handleInputChange({ target: { name: 'coloresVestimenta', value: coloresString }});
  };

  // Parse the colors from string or use default values
  const colores = values.coloresVestimenta ? 
    JSON.parse(values.coloresVestimenta) : 
    {
      color1: "#FFFFFF",
      color2: "#4A3728",
      color3: "#D2B48C",
      color4: "#1A1A1A"
    };

  const colorLabels = {
    color1: "Color 1",
    color2: "Color 2",
    color3: "Color 3",
    color4: "Color 4"
  };

  return (
    <div className="space-y-6">
      <div>
        <Label htmlFor="Vestimenta" className="block text-sm font-medium text-gray-700 mb-1">
          Código de Vestimenta
        </Label>
        <Input
          id="Vestimenta"
          name="Vestimenta"
          type="text"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.Vestimenta || ''}
          className="w-full rounded-md"
          placeholder="Ejemplo: Formal"
        />
      </div>

      <div>
        <Label htmlFor="VestimentaAviso" className="block text-sm font-medium text-gray-700 mb-1">
          Aviso o Mensaje Adicional
        </Label>
        <textarea
          id="VestimentaAviso"
          name="VestimentaAviso"
          rows="4"
          onChange={(e) => { handleChange(e); handleInputChange(e); }}
          value={values.VestimentaAviso || ''}
          className="w-full rounded-md border-gray-300"
          placeholder="Mensaje adicional sobre la vestimenta"
        />
      </div>

      <div className="border-t pt-6">
        <h3 className="text-lg font-semibold mb-4">Paleta de Colores Sugerida</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {Object.entries(colorLabels).map(([key, label]) => (
            <div key={key} className="flex flex-col items-center">
              <Label className="block text-sm font-medium text-gray-700 mb-1">
                {label}
              </Label>
              <Input
                type="color"
                value={colores[key]}
                onChange={(e) => handleColorChange(key, e.target.value)}
                className="h-10 w-full"
              />
            </div>
          ))}
        </div>
      </div>

      <ImageFormComponent
        label="Imagen de Fondo para Vestimenta"
        field="dressingCodeBackground"
        element={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mt-4">
        <p className="text-blue-700">
          <span className="font-bold">Nota:</span> Especifica claramente el código de vestimenta y cualquier detalle adicional que los invitados deban conocer.
        </p>
      </div>
    </div>
  );
}; 