import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "reactstrap";
import GaleriaTable from "../../components/ComponentsAuto/GaleriaTable";
import { ImageFondo } from "./FormsComponent/Images/ImageFondo";
import { validateToken } from "../../components/validateToken";
import toast from "react-hot-toast";
import SelectTipoLetra from "../../components/functions/SelectTipoLetra";
import { SelectIcons } from "../../components/functions/SelectIcons";
import { SelectSobre } from "../../components/functions/SelectSobre";
import { AddHistorial } from "../../components/functions/AddHistorial";
import { InstagramForm } from "./DetallesComponent/InstagramForm";
import { ColoresEventoForm } from './DetallesComponent/ColoresEventoForm';
import { ContadorForm } from './DetallesComponent/ContadorForm';
import { PadresForm } from './DetallesComponent/PadresForm';
import { CeremoniaForm } from './DetallesComponent/CeremoniaForm';
import { ConfirmacionForm } from './DetallesComponent/ConfirmacionForm';
import { RegalosForm } from './DetallesComponent/RegalosForm';
import { VestimentaForm } from './DetallesComponent/VestimentaForm';
import { InvitadosForm } from './DetallesComponent/InvitadosForm';
import { DetallesNavbar } from './DetallesComponent/DetallesNavbar';

export const XvDetalles = () => {
  const [datosInvitacion, setDatosInvitacion] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataEvento, setDataEvento] = useState({});
  const [userName, setUserName] = useState("");
  const [resetMessage, setResetMessage] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idClient = searchParams.get("id");

  const fetchData = async () => {
    try {
      const responseEvent = await axios.get(`/api/Eventos/${idClient}`);
      setDataEvento(responseEvent.data);
      console.log(responseEvent);
      const response = await axios.get(
        `/api/Seccionportada/obtenerDiseno2xv/${idClient}`
      );
      setDatosInvitacion(response.data);
      console.log(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Error");
    }
  };

  const actualizarCeremonia = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionceremonium/${values.ceremoniaId}`, values);
      toast.success("Ceremonia actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la ceremonia.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarColores = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Colores/${values.colorId}`, values);
      toast.success("Colores actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los colores.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarContador = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccioncontador/${values.contadorId}`, values);
      toast.success("Contador actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar el contador.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarInstagram = async (values) => {
    console.log(values);
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/InstagramPosts/${values.idInstagram}`, values);
      toast.success("Instagram actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar Instagram.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarInvitados = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccioninvitado/${values.invitadosId}`, values);
      toast.success("Invitados actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los invitados.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarConfirmacion = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(
        `/api/Seccionconfirmacion/${values.confirmacionId}`,
        values
      );
      toast.success("Confirmación actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la confirmación.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarHospedaje = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionhospedaje/${values.hospedajeId}`, values);
      toast.success("Hospedaje actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar el hospedaje.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarPadres = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionpadre/${values.padresId}`, values);
      toast.success("Padres actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los padres.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarPortada = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionportadum/${values.portadaId}`, values);
      toast.success("Portada actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la portada.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarVestimenta = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      // Ensure the colores field is properly formatted
      if (values.colores && typeof values.colores === 'object') {
        values.colores = JSON.stringify(values.colores);
      }
      await axios.put(`/api/Seccionvestimentum/${values.vestimentaId}`, values);
      toast.success("Vestimenta actualizada correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar la vestimenta.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarPrograma = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionprograma/${values.programaId}`, values);
      toast.success("Programa actualizado correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar el programa.");
    } finally {
      toast.dismiss();
    }
  };

  const actualizarRegalos = async (values) => {
    toast.loading("Actualizando...");
    try {
      console.log(values);
      await axios.put(`/api/Seccionregalo/${values.regalosId}`, values);
      toast.success("Regalos actualizados correctamente.");
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      toast.error("Error al actualizar los regalos.");
    } finally {
      toast.dismiss();
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    validateToken();
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    validateAndFetchData();
  }, []);

  return (
    <div className="md:ml-64">
      {loading ? (
        <div>Cargando XV...</div>
      ) : (
        <div className="w-full p-10 mx-auto mt-10 bg-white rounded-md shadow-md">
          <h2 className="text-3xl font-semibold mb-4">Detalles del evento</h2>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Iconos</h3>
            <p className="mb-4">
              Accede a nuestra galería de iconos para personalizar tu
              invitación:
            </p>
            <a
              href="/iconos"
              className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Ver Galería de Iconos
            </a>
          </div>

          <DetallesNavbar dataEvento={dataEvento} idClient={idClient} userName={userName} />
          {/* <CeremoniaForm
            initialValues={datosInvitacion}
          />  */}

          <br />
          <br />
          <div id="colores-portada">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
              COLORES
            </h2>
            <ColoresEventoForm
              initialValues={datosInvitacion?.colores}
              onSubmit={(values) => {
                console.log(values);
                actualizarColores(values);
              }}
            />
          </div>
          <br />
          <br />
          <h2 className="text-4xl font-semibold w-full text-center mb-8 mt-12">
            PORTADA
          </h2>
          <Formik
            initialValues={datosInvitacion?.portada}
            onSubmit={(values) => {
              console.log("Formulario de portada enviado:", values);
              actualizarPortada(values);
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                className="bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="cancion"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Canción URL Youtube:
                    </label>
                    <Input
                      type="text"
                      id="cancion"
                      name="cancion"
                      value={values?.cancion}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="fechaDeBoda"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Fecha de Evento:
                    </label>
                    <Input
                      type="text"
                      id="fechaDeBoda"
                      name="fechaDeBoda"
                      value={values?.fechaDeBoda}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="nombre"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Nombre:
                    </label>
                    <Input
                      type="text"
                      id="nombre"
                      name="nombre"
                      value={values?.nombre}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="titulo"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Título:
                    </label>
                    <Input
                      type="text"
                      id="titulo"
                      name="titulo"
                      value={values?.titulo}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="imgPortada"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Imagen de Portada:
                    </label>
                    <Input
                      type="text"
                      id="imgPortada"
                      name="imgPortada"
                      value={values?.imgPortada}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="textColor"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Color Texto:
                    </label>
                    <Input
                      type="color"
                      id="textColor"
                      name="textColor"
                      value={values?.textColor || "#ffffff"}
                      onChange={handleChange}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <SelectSobre values={values} handleChange={handleChange} />

                  <div>
                    <SelectTipoLetra
                      values={values}
                      handleChange={handleChange}
                    />
                    <div>
                      <label
                        htmlFor="posicion"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Posición titulo:
                      </label>
                      <select
                        id="posicion"
                        name="posicion"
                        value={values?.posicion || ""}
                        onChange={handleChange}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      >
                      <option value="">Normal</option>
                      <option value="arriba">Arriba</option>
                      <option value="abajo">Abajo</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Guardar Portada
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <br />
          {dataEvento.enableContador ? (
            <div id="contador">
              <ContadorForm
                initialValues={datosInvitacion?.contador}
                onSubmit={(values) => {
                  console.log("Formulario de contador enviado:", values);
                  actualizarContador(values);
                }}
              />
            </div>
          ) : null}

          <br />
          {dataEvento.enablePadres ? (
            <div id="padres">
              <PadresForm
                initialValues={datosInvitacion?.padres}
                onSubmit={(values) => {
                  console.log("Formulario de padres y padrinos enviado:", values);
                  actualizarPadres(values);
                }}
              />
            </div>
          ) : null}

          <br />
          {dataEvento.enableCeremonia ? (
            <div id="ceremonia">
              <CeremoniaForm
                initialValues={datosInvitacion?.ceremonia}
                onSubmit={(values) => {
                  actualizarCeremonia(values);
                }}
              />
            </div>
          ) : null}

          <div id="confirmacion">
            <ConfirmacionForm
              initialValues={datosInvitacion?.confirmacion}
              onSubmit={(values) => {
                console.log("Formulario de confirmación enviado:", values);
                actualizarConfirmacion(values);
              }}
            />
          </div>

          <br />
          {dataEvento.enableHospedaje ? (
            <div id="hospedaje" className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  HOSPEDAJE
                </h2>
                <Formik
                  initialValues={datosInvitacion?.hospedaje}
                  onSubmit={(values) => {
                    console.log("Formulario de hospedaje enviado:", values);
                    actualizarHospedaje(values);
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="descripcion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Descripción:
                          </label>
                          <Input
                            type="text"
                            id="descripcion"
                            name="descripcion"
                            value={values?.descripcion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="disponibleDos"
                            className="flex items-center"
                          >
                            <Input
                              type="checkbox"
                              id="disponibleDos"
                              name="disponibleDos"
                              checked={values?.disponibleDos}
                              onChange={handleChange}
                              className="mr-2"
                            />
                            <span className="text-sm font-medium text-gray-700">
                              Disponible Dos
                            </span>
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor="hotel1Titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título del Hotel 1:
                          </label>
                          <Input
                            type="text"
                            id="hotel1Titulo"
                            name="hotel1Titulo"
                            value={values?.hotel1Titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel1lugar"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Lugar del Hotel 1:
                          </label>
                          <Input
                            type="text"
                            id="hotel1lugar"
                            name="hotel1lugar"
                            value={values?.hotel1lugar}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel1url"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL del Hotel 1:
                          </label>
                          <Input
                            type="text"
                            id="hotel1url"
                            name="hotel1url"
                            value={values?.hotel1url}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel2Titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Título del Hotel 2:
                          </label>
                          <Input
                            type="text"
                            id="hotel2Titulo"
                            name="hotel2Titulo"
                            value={values?.hotel2Titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel2lugar"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Lugar del Hotel 2:
                          </label>
                          <Input
                            type="text"
                            id="hotel2lugar"
                            name="hotel2lugar"
                            value={values?.hotel2lugar}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hotel2url"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            URL del Hotel 2:
                          </label>
                          <Input
                            type="text"
                            id="hotel2url"
                            name="hotel2url"
                            value={values?.hotel2url}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${values?.imgFondo ? "input-error" : ""
                              }`}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Hospedaje
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento.enablePrograma ? (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="p-6">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  PROGRAMA
                </h2>
                <Formik
                  initialValues={datosInvitacion?.programa}
                  onSubmit={(values) => {
                    console.log("Formulario de programa enviado:", values);
                    actualizarPrograma(values);
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="titulo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Titulo Principal:
                          </label>
                          <Input
                            type="text"
                            id="titulo"
                            name="titulo"
                            value={values?.titulo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div className="col-span-2">
                          <label
                            htmlFor="descripcion"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Descripcion:
                          </label>
                          <Input
                            type="text"
                            id="descripcion"
                            name="descripcion"
                            value={values?.descripcion}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>

                      {[
                        {
                          name: "ceremoniaReligiosa",
                          label: "Seccion",
                          number: 1,
                        },
                        { name: "recepcion", label: "Seccion", number: 2 },
                        { name: "baileXv", label: "Seccion", number: 3 },
                        { name: "coctel", label: "Seccion", number: 4 },
                        { name: "cena", label: "Seccion", number: 5 },
                        { name: "baile", label: "Seccion", number: 6 },
                      ].map((item) => (
                        <div
                          key={item.name}
                          className="grid grid-cols-1 md:grid-cols-3 gap-6"
                        >
                          <div>
                            <label
                              htmlFor={item.name}
                              className="block text-sm font-medium text-gray-700 mb-1"
                            >
                              {`${item.label} (${item.number}):`}
                            </label>
                            <Input
                              type="text"
                              id={item.name}
                              name={item.name}
                              value={values?.[item.name]}
                              onChange={handleChange}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div>
                            <SelectIcons
                              label={`Selecciona un icono para ${item.label} (${item.number})`}
                              name={`${item.name}Img`}
                              onChange={handleChange}
                              value={values?.[`${item.name}Img`]}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`${item.name}Titulo`}
                              className="block text-sm font-medium text-gray-700 mb-1"
                            >
                              {`${item.label} Titulo (${item.number}):`}
                            </label>
                            <Input
                              type="text"
                              id={`${item.name}Titulo`}
                              name={`${item.name}Titulo`}
                              value={values?.[`${item.name}Titulo`]}
                              onChange={handleChange}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      ))}

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                          <label
                            htmlFor="textColor"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Color de Texto:
                          </label>
                          <Input
                            type="color"
                            id="textColor"
                            name="textColor"
                            value={values?.textColor || ""}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de fondo:
                          </label>
                          <Input
                            type="text"
                            id="imgFondo"
                            name="imgFondo"
                            value={values?.imgFondo}
                            onChange={handleChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="imgFondo"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Imagen de Fondo:
                          </label>
                          <ImageFondo
                            label="Seleccione un fondo"
                            name="imgFondo"
                            onChange={handleChange}
                            value={values?.imgFondo}
                            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${values?.imgFondo ? "input-error" : ""
                              }`}
                          />
                        </div>
                        <div>
                          <SelectTipoLetra
                            values={values}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="flex justify-end mt-6">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Guardar Programa
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : null}

          <br />
          {dataEvento?.enableRegalos ? (
            <div id="regalos">
              <RegalosForm
                initialValues={datosInvitacion?.regalos}
                onSubmit={(values) => {
                  console.log("Formulario de Mesa de Regalos enviado:", values);
                  actualizarRegalos(values);
                }}
              />
            </div>
          ) : null}

          <br />
          {dataEvento?.enableVestimenta ? (
            <div id="vestimenta">
              <VestimentaForm
                initialValues={datosInvitacion?.vestimenta}
                onSubmit={(values) => {
                  console.log("Formulario de Vestimenta enviado:", values);
                  actualizarVestimenta(values);
                }}
              />
            </div>
          ) : null}

          <div id="invitados">
            <InvitadosForm
              initialValues={datosInvitacion?.invitados}
              onSubmit={(values) => {
                console.log("Formulario de Invitados enviado:", values);
                actualizarInvitados(values);
              }}
            />
          </div>

          {dataEvento?.enableInsta ? (
            <div id="instagram">
              <InstagramForm dataInsta={datosInvitacion?.insta} actualizarInstagram={actualizarInstagram} />
            </div>
          ) : null}

          <div id="galeria">
            <GaleriaTable idEvento={idClient} />
          </div>
        </div>
      )}
    </div>
  );
};
