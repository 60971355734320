import React, { useState, useEffect } from 'react';
import { AddHistorial } from '../../../components/functions/AddHistorial';
import axios from 'axios';
import { ModalEditarImagenes } from '../Modals/ModalImages';
import toast from 'react-hot-toast';

export const DetallesNavbar = ({ dataEvento, idClient, userName }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalImagen, setModalImagen] = useState(false);
  const [infoImages, setInfoImages] = useState(null);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  const toggleModalImages = async (id) => {
    const response = await axios.get(`/api/Images/FromEvent/${id}`);
    if (response.status !== 200) {
      toast.error("No se pudo obtener la información de las imágenes para este evento");
      return;
    }

    setInfoImages(response.data);
    setModalImagen(true);
  };

  const navItems = [
    { id: 'colores-portada', label: 'Colores y Portada', enabled: true },
    { id: 'contador', label: 'Contador', enabled: dataEvento?.enableContador },
    { id: 'padres', label: 'Padres y Padrinos', enabled: dataEvento?.enablePadres },
    { id: 'ceremonia', label: 'Ceremonia', enabled: dataEvento?.enableCeremonia },
    { id: 'confirmacion', label: 'Confirmación', enabled: true },
    { id: 'hospedaje', label: 'Hospedaje', enabled: dataEvento?.enableHospedaje },
    { id: 'regalos', label: 'Mesa de Regalos', enabled: dataEvento?.enableRegalos },
    { id: 'vestimenta', label: 'Vestimenta', enabled: dataEvento?.enableVestimenta },
    { id: 'invitados', label: 'Invitados', enabled: true },
    { id: 'instagram', label: 'Instagram', enabled: dataEvento?.enableInsta },
    { id: 'galeria', label: 'Galería', enabled: true }
  ];

  return (
    <div className="flex">
      {/* Sidebar para desktop */}
      <div className="hidden md:flex flex-col w-64 h-screen bg-white shadow-lg fixed left-0 top-0">
        <div className="p-5 bg-gradient-to-r from-indigo-600 to-purple-600">
          <h2 className="text-xl font-bold text-white">Navegación</h2>
        </div>
        <div className="flex-1 overflow-y-auto py-6">
          {navItems
            .filter(item => item.enabled)
            .map(item => (
              <button
                key={item.id}
                onClick={() => scrollToSection(item.id)}
                className="block w-full text-left px-6 py-3 text-sm font-medium text-gray-700 hover:bg-indigo-50 hover:text-indigo-700 transition-all duration-200 border-l-4 border-transparent hover:border-indigo-600"
              >
                {item.label}
              </button>
            ))}
        </div>
        <div className="border-t border-gray-200 p-5 bg-gray-50">
          <AddHistorial evento={idClient} nombre={userName} />
          <br />
        <button
          onClick={() => toggleModalImages(idClient)}
          className="bg-purple-100 text-purple-600 hover:bg-purple-200 p-2 rounded-full"
        >
          🖼️ Editar Imágenes
        </button>
        </div>
      </div>

      {/* Sidebar móvil */}
      <div
        className={`fixed h-screen inset-y-0 left-0 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          } md:hidden bg-white w-72 z-50 shadow-xl transition-transform duration-300 ease-in-out flex flex-col`}
      >
        <div className="flex items-center justify-between p-5 bg-gradient-to-r from-indigo-600 to-purple-600">
          <h2 className="text-xl font-bold text-white">Navegación</h2>
          <button
            onClick={() => setIsMenuOpen(false)}
            className="text-white hover:text-gray-200 focus:outline-none"
          >
            <svg
              className="h-7 w-7"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex-1 overflow-y-auto py-6">
          {navItems
            .filter(item => item.enabled)
            .map(item => (
              <button
                key={item.id}
                onClick={() => scrollToSection(item.id)}
                className="block w-full text-left px-6 py-3 text-sm font-medium text-gray-700 hover:bg-indigo-50 hover:text-indigo-700 transition-all duration-200 border-l-4 border-transparent hover:border-indigo-600"
              >
                {item.label}
              </button>
            ))}
        </div>
        <div className="border-t border-gray-200 p-5 bg-gray-50">
          <AddHistorial evento={idClient} nombre={userName} />
          <br />
          <button
          onClick={() => toggleModalImages(idClient)}
          className="bg-purple-100 text-purple-600 hover:bg-purple-200 p-2 rounded-full"
        >
          🖼️ Editar Imágenes
        </button>
        </div>

        {modalImagen && (
        <ModalEditarImagenes
          modalImages={modalImagen}
          setModalImages={setModalImagen}
          infoImages={infoImages}
          edit={true}
        />
      )}
      </div>

      {/* Botón de menú hamburguesa para móvil */}
      <button
        onClick={() => setIsMenuOpen(true)}
        className="md:hidden fixed top-4 left-4 z-40 bg-indigo-600 p-3 rounded-lg shadow-lg text-white hover:bg-indigo-700 focus:outline-none transition-colors duration-200"
      >
        <svg
          className="h-6 w-6"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>

      {/* Overlay para cerrar el sidebar al hacer clic fuera */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm md:hidden z-40"
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </div>
  );
}; 