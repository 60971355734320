import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Input } from 'reactstrap';
import { ImageFondo } from '../FormsComponent/Images/ImageFondo';
import SelectTipoLetra from '../../../components/functions/SelectTipoLetra';

export const InvitadosForm = ({ initialValues, onSubmit }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="p-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
          INVITADOS
        </h2>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            if (values.limiteAccesos == null) {
              values.limiteAccesos = 150;
            }
            onSubmit(values);
          }}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <Form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="titulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Titulo:
                  </label>
                  <Input
                    type="text"
                    id="titulo"
                    name="titulo"
                    value={values?.titulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="titulo" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="entradasText" className="block text-sm font-medium text-gray-700 mb-1">
                    Entradas Text:
                  </label>
                  <Input
                    type="text"
                    id="entradasText"
                    name="entradasText"
                    value={values?.entradasText}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="entradasText" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="eventoTitulo" className="block text-sm font-medium text-gray-700 mb-1">
                    Evento Titulo:
                  </label>
                  <Input
                    type="text"
                    id="eventoTitulo"
                    name="eventoTitulo"
                    value={values?.eventoTitulo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="eventoTitulo" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="colorFondoName" className="block text-sm font-medium text-gray-700 mb-1">
                    Color Fondo Name:
                  </label>
                  <Input
                    type="color"
                    id="colorFondoName"
                    name="colorFondoName"
                    value={values?.colorFondoName}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="colorFondoName" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <Input
                    type="text"
                    id="imgFondo"
                    name="imgFondo"
                    value={values?.imgFondo}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="imgFondo" className="block text-sm font-medium text-gray-700 mb-1">
                    Imagen de fondo:
                  </label>
                  <ImageFondo
                    label="Seleccione un fondo"
                    name="imgFondo"
                    onChange={handleChange}
                    value={values?.imgFondo}
                    className={`mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${values?.imgFondo ? "input-error" : ""}`}
                  />
                  <ErrorMessage name="imgFondo" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <label htmlFor="limiteAccesos" className="block text-sm font-medium text-gray-700 mb-1">
                    Limite de Invitados:
                  </label>
                  <Input
                    type="text"
                    id="limiteAccesos"
                    name="limiteAccesos"
                    value={values?.limiteAccesos}
                    onChange={handleChange}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <ErrorMessage name="limiteAccesos" component="div" className="text-red-500 text-sm mt-1" />
                </div>
                <div>
                  <SelectTipoLetra values={values} handleChange={handleChange} />
                </div>
              </div>
              <div className="flex justify-end mt-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Guardar Invitados
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}; 