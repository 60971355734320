/* global gtag */
import React, { useEffect } from 'react';

// Google Ads conversion tracking function
function gtag_report_conversion(url) {
  console.log('khee');
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-16738412224/pvRgCKimqeEZEMDNv60-',
      'event_callback': callback
  });
  return false;
}

export const LeadClickWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.jsdelivr.net/npm/@leadsales/leadclick@1.11.1/dist/leadclick/leadclick.esm.js';
    document.body.appendChild(script);

    // Add click event listener for the LeadClick widget's WhatsApp button
    const handleClick = (event) => {
      // Check if the click is on the LeadClick widget's WhatsApp button
      const target = event.target.closest('leadclick-widget a[href*="whatsapp"]');
      if (target) {
        gtag_report_conversion();
      }
    };

    // Wait for the widget to be loaded
    setTimeout(() => {
      document.addEventListener('click', handleClick, true);
    }, 1000);

    return () => {
      document.body.removeChild(script);
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <leadclick-widget
      integrations="2"
      fburl="https://www.facebook.com/profile.php?id=61556089589338"
      igurl="https://www.instagram.com/leadsales.io/?hl=es"
      waurl="https://api.whatsapp.com/send?phone=5214491516931&text=Hola%20buen%20d%C3%ADa%20me%20gustar%C3%ADa%20adquirir%20informaci%C3%B3n"
      cta="Contactanos"
      fontcolor="#ffffff"
      bgcolor="#000000"
      instructions="Por dónde nos quieres contactar?"
      orientation="right"
      visible_integrations="WhatsApp,Facebook"
      ispremium="false"
    >
    </leadclick-widget>
  );
}; 